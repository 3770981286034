import { Episode, Episodic, Movie } from '@types';
import { MovieDetailViewer } from 'components/movies';
import Netflix from 'components/netflix';
import { EpisodicViewer } from 'components/tv-shows';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './searchbrowser.module.scss';

type MovieOrEpisodic = Movie | Episodic;

const MS_IN_2_WEEKS = 1000 * 60 * 60 * 24 * 14;
const sorting = (l: any, r: any) => l.year === r.year
    ? l.title > r.title ? 1 : -1
    : l.year > r.year ? -1 : 1;
const RE_STRIP_UNWANTED_WORDS = /\b(on|the|in|and|of|to)\b/gi;

/** Component for displaying the matching results for the user search. */
export default function SearchBrowser() {
    const location = useLocation();
    const [videos, setVideos] = useState<MovieOrEpisodic[]>([]);
    const [showEpisodicViewer, setShowEpisodicViewer] = useState(false);
    const [showMovieDetailViewer, setShowMovieDetailViewer] = useState(false);
    const [model, setModel] = useState<MovieOrEpisodic>();
    const twoWeeksAgo = new Date(Date.now() - MS_IN_2_WEEKS);
    const params = new URLSearchParams(location.search);
    const query = params.get('q');
    const tags = params.getAll('tags').filter(t => t !== 'new-and-trending');

    useEffect(() => {
        document.title = `${Object.keys(videos).length} Results for '${query || tags.join(', ')}' - movies-site`;
    }, [videos, query, tags]);

    const filter = (video: Movie|Episode) => {
        if (params.getAll('tags').includes('new-and-trending'))
            return 'createdOn' in video && video.createdOn >= twoWeeksAgo;
        else if (tags.length && 'tags' in video && video.tags?.length)
            return tags.filter(t => video.tags.includes(t)).length === tags.length;
        else if (query && 'series' in video)
            return new RegExp(query.trim().split(/\s+/g).filter(s => !RE_STRIP_UNWANTED_WORDS.test(s)).join('|'), 'gi').test(video.series);
        else if (query)
            return new RegExp(query.trim().split(/\s+/g).filter(s => !RE_STRIP_UNWANTED_WORDS.test(s)).join('|'), 'gi').test(video.title);
        else
            return false;
    };
    // Callback for playing a video when the user clicks on the tile
    const handleVideoClick = (video: MovieOrEpisodic) => {
        setModel(video);

        if ('seasons' in video && video.seasons?.length)
            setShowEpisodicViewer(true);
        else
            setShowMovieDetailViewer(true);
    };

    return (
        <>
            <div className={styles['search-results']}>
                <div className="lolomo is-fullbleed">
                    <Netflix.Queried key={`${query}|${tags.join(';')}`}
                        title={`Found ${videos.length} result(s) for '${query}'`}
                        filter={filter}
                        query={query}
                        sorting={sorting}
                        limit={99}
                        onShowVideoDetails={handleVideoClick}
                        onLoad={(videos: MovieOrEpisodic[]) => setVideos(videos)}
                    />
                </div>
            </div>

            <MovieDetailViewer show={showMovieDetailViewer}
                model={model && 'id' in model ? model : undefined}
                onHide={() => {
                    setShowMovieDetailViewer(false);
                    setModel(undefined);
                }}
            />

            <EpisodicViewer show={showEpisodicViewer}
                model={model && 'seasons' in model ? model : undefined}
                onHide={() => {
                    setShowEpisodicViewer(false);
                    setModel(undefined);
                }}
            />
        </>
    );
}
