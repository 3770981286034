import { Movie } from "@types";

export const TILE_WIDTH = 16.66666666666667;
export const TILES_PER_ROW = 6;
export const EMPTY_VIDEO: Movie = {
    id: '',
    title: '',
    year: 0,
    createdOn: new Date(1970, 0, 1),
    tags: [],
    imdb: '',
    mpaa: '',
    runtime: '',
    poster: '',
    subs: false,
    backdrops: []
}
