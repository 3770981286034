import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { useLocation, Navigate, Routes, Route } from 'react-router-dom';
import Login from './Login';

function nullif<T>(o: T, ...replacements: T[]): T | null {
    if (replacements.includes(o))
        return null;
    else
        return o;
}

/** Component that prevents unauthenticated users from accessing. */
export default function Authenticator(props: React.PropsWithChildren) {
    const { pathname, search, hash, state } = useLocation();

    console.log(`Navigated to ${pathname}`, { pathname, search, hash, state });

    return (
        <>
            <AuthenticatedTemplate>
                {!pathname.startsWith('/login') && props.children}
                {pathname.startsWith('/login') && <Navigate replace to={{ pathname: nullif(state?.pathname, '/login') || '/', search: search }} />}
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                {!pathname.startsWith('/login') && <Navigate replace to={{ pathname: '/login' }} state={{ pathname, search, hash }} />}
                {pathname.startsWith('/login') && <Routes><Route path='/login' element={<Login referrer={state} />} /></Routes>}
            </UnauthenticatedTemplate>
        </>
    );
}
