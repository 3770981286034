import { Movie, Video } from '@types';
import MovieManager from 'managers/MovieManager';
import { useEffect, useState } from 'react';
import NetflixRowHeader from '../Netflix.Header';
import NetflixTile from '../NetflixTile';
import NetflixRowLoading from './NetflixRowLoading';
import { EMPTY_VIDEO, TILES_PER_ROW } from './config';

const movieManager = new MovieManager();

interface NetflixRowQueriedProps {
    title: string;
    filter: (vid: Video) => boolean;
    query?: string | null;
    sorting?: (left: Video, right: Video) => number;
    limit?: number;
    onShowVideoDetails?: (movie: Movie) => void;
    onLoad?: (movies: Movie[]) => void;
    href?: string;
}

/** Component used to display a list of custom-filtered videos in a Netflix-style scrollable row. */
export default function NetflixRowQueried(props: NetflixRowQueriedProps) {
    const [loading, setLoading] = useState(true);
    const [movies, setMovies] = useState<(Movie & { key?: React.Key })[]>(Array(TILES_PER_ROW * 3).fill(EMPTY_VIDEO));
    const { query, filter, sorting, limit, onLoad } = props;

    useEffect(() => {
        let cancelled = false;

        (async () => {
            const _movies: Movie[] = [];

            if (query)
                _movies.push(...await movieManager.search(query.trim()));
            else {
                for await (const movie of movieManager.filter(filter))
                    _movies.push(movie);
            }

            if (cancelled)
                return;
            if (_movies.length)
                setMovies(_movies.sort(sorting || (_ => 0)).slice(0, limit || 24));
            else
                setMovies([]);

            setLoading(false);
            onLoad?.(_movies);
        })();

        return () => cancelled = true as any;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const tiles = movies;
    const renderme = !(!loading && !tiles.length && props.title === 'Continue Watching');

    return (
        <>
            {
                renderme &&
                <>
                    {loading && <NetflixRowLoading />}

                    {
                        !loading &&
                        <div className="lolomoRow lolomoRow_title_card css-0" data-list-context="queried">
                            <NetflixRowHeader title={props.title} href={props.href} />
                            <div className="rowContainer rowContainer_title_card">
                                <div className="ptrack-container">
                                    <div className="rowContent slider-hover-trigger-layer">
                                        <div className="slider">
                                            <div className="sliderMask showPeek">
                                                <div className="sliderContent row-with-x-columns row-with-x-columns-wrapped">
                                                    {
                                                        tiles.map((movie, i) => {
                                                            return (
                                                                <NetflixTile key={movie.key ?? movie.id ?? i}
                                                                    index={i}
                                                                    model={movie}
                                                                    onShowVideoDetails={props.onShowVideoDetails}
                                                                    data-label={movie.title}
                                                                />
                                                            );
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {
                        !loading && !tiles.length &&
                        <h6 className="display-6 text-center">No matches found...</h6>
                    }
                </>
            }
        </>
    );
}
