import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import Authenticator from './components/authentication/Authenticator';
import { MovieListViewer, MoviePlayer } from './components/movies';
import { NetflixBrowser } from './components/netflix';
import { SearchBrowser } from './components/search';
import { EpisodicBrowser, EpisodicPlayer } from './components/tv-shows';

import './App.css';

export default function App() {
  return (
    <Router>
      <Authenticator>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<NetflixBrowser />} />
            <Route path="/movies" element={<MovieListViewer />} />
            <Route path="/movies/:id" element={<MoviePlayer />} />
            <Route path="/tv-shows" element={<EpisodicBrowser />} />
            <Route path="/tv-shows/:id" element={<EpisodicPlayer />} />
            <Route path="/search" element={<SearchBrowser />} />
          </Route>
        </Routes>
      </Authenticator>
    </Router>
  );
}
