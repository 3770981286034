import { msal } from 'config';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Navigator from './navigation/Navigator';

const MS_PER_DAYS = 1000 * 60 * 60 * 24;
const MSAL_TOKEN_EXPIRATION_WARNING = MS_PER_DAYS * 7;

/** Component that wraps the site pages. */
export default function Layout(props: React.PropsWithChildren) {
    useEffect(() => {
        const msalTokenExpires = new Date(new URLSearchParams(msal.sas).get('se') ?? '');

        if ((Date.now() + MSAL_TOKEN_EXPIRATION_WARNING) >= msalTokenExpires.valueOf() && msal.user === msal.admin)
            alert(`MSAL SAS token expires in less than 7 days!`);
    }, []);

    return (
        <>
            <Navigator />
            <main>
                <Outlet />
            </main>
        </>
    );
}
