import { Episodic, Movie } from '@types';
import { ReactComponent as JawboneChevronSvg } from 'assets/bob-jawbone-chevron.svg';
import { ReactComponent as SubtitlesSvg } from 'assets/icon-subtitles.svg';
import { ReactComponent as ImdbSvg } from 'assets/imdb.svg';
import Icon from 'components/Icon';
import { msal } from 'config';
import MovieManager from 'managers/MovieManager';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './netflix.overwrite.module.scss';

// Only movies have top level tags, so this is fine (for now)
const movieManager = new MovieManager();

type MovieOrEpisodic = Movie | Episodic;

interface NetflixTileProps<T> extends React.HTMLAttributes<HTMLDivElement> {
    index: number;
    model: T;
    onShowVideoDetails?: (video: T) => void;
}

/** Component used to display info for a single video. */
export default function NetflixTile<T extends MovieOrEpisodic>(props: NetflixTileProps<T>) {
    const { index, model, onShowVideoDetails, ...rest } = props;
    const [video, setVideo] = useState(model || {});
    const [pid, setPid] = useState(-1);
    const [backdrop, setBackdropSrc] = useState(video.backdrops[0]);
    const [rating, setRating] = useState(0);

    /** Clear out the backdrop cycling after unmount */
    useEffect(() => { return () => clearInterval(pid) }, [pid]);
    /** Query the IMDb API for a rating and update the img tag with the score */
    useEffect(() => {
        let cancelled = false;

        (async () => {
            if (video.imdb && window.imdb?.rating) {
                const { rating } = await window.imdb.rating.parse(video.imdb) || {};

                if (rating && !cancelled)
                    setRating(rating);
            }
        })();

        return () => cancelled = true as any;
    }, [video.imdb]);

    /** Function called when the user edits the vid tags */
    const handleEditTags = async () => {
        if ('id' in video) {
            const tags = prompt('Enter tags (space delimited)', video.tags.join(' '))?.split(' ') || [];

            if (tags.length && tags.join(' ') !== video.tags.join(' ')) {
                const updated = await movieManager.update(video.id, { tags: tags.filter(t => t.trim()) });

                setVideo(updated as any);
            }
        }
    };
    /** Function callback for when the 'View Details' button is clicked */
    const handleClick = () => {
        onShowVideoDetails?.(video);
    };
    /** Start the backdrop cycling */
    const handleMouseEnter = () => {
        let i = 0;
        setBackdropSrc(video.backdrops.at(++i % video.backdrops.length) ?? '');
        setPid(setInterval(() => {
            setBackdropSrc(video.backdrops.at(++i % video.backdrops.length) ?? '');
        }, 2500) as any);
    };
    /** Revert the vid backdrop to the original img */
    const handleMouseLeave = () => {
        setBackdropSrc(video.backdrops.at(0) ?? '');
        clearInterval(pid);
    };

    // TODO: Is this really the best place for this logic?
    // Will probably end up creating a <Episodic.Tile /> wrapper for this component anyways
    const href = 'seasons' in video
        ? `/tv-shows/${video.seasons[0].episodes[0].id}`
        : `/movies/${video.id}`;
    const hours = 'id' in video ? Number(video.runtime?.match(/\d+(?=h)/g)?.[0]) : NaN;
    const minutes = 'id' in video ? Number(video.runtime?.match(/\d+(?=m)/g)?.[0]) : NaN;
    const duration = hours * 60 * 60 + minutes * 60;
    const id = 'id' in video
        ? video.id
        : video.seasons[0].episodes[0].id;
    let currentTime = 0;

    if (`${id}:meta` in window.localStorage) {
        const meta = JSON.parse(window.localStorage[`${id}:meta`]);
        currentTime = Number(meta.currentTime);
    }

    return (
        <>
            {
                !('id' in video) &&
                <div className="slider-item slider-item- smallTitleCard loadingTitle">
                    <div
                        className="ratio-16x9 pulsate"
                        style={{ animationDelay: `${index * 0.2}s` }}
                    />
                </div>
            }
            {
                !!('id' in video) &&
                <div className={`slider-item slider-item-${props.index}`} {...rest}>
                    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className={`title-card-container ${styles["title-card-container"]} css-0`}>
                        <div className="title-card">
                            <div className="ptrack-content">
                                <Link to={href}
                                    tabIndex={index}
                                    aria-hidden="false"
                                    className="slider-refocus"
                                    title={`Play ${video.title} (${video.year})`}
                                    aria-label={`Play ${video.title} (${video.year})`}
                                >
                                    {/* Meta */}
                                    <div className={styles['video-meta']}>
                                        {
                                            !!video.imdb &&
                                            <span className={`badge ${styles['badge-imdb']} me-1`}>
                                                <ImdbSvg />
                                                {!!rating && <span className="ms-2">{rating.toFixed(1)}</span>}
                                            </span>
                                        }
                                        {!!video.mpaa && <span className="badge me-1">{video.mpaa}</span>}
                                        {!!video.runtime && <span className="badge me-1">{video.runtime}</span>}
                                        {!!video.subs && <span className={`${styles['badge-subs']} ms-2`}><SubtitlesSvg /></span>}
                                        {!!video.quality && <span className="badge ms-auto">{video.quality}</span>}
                                        {!!video.isNewOrTrending && <span className="badge badge-new-and-trending ms-auto">New</span>}
                                    </div>

                                    <div className="boxart-size-16x9 boxart-container boxart-rounded">
                                        <img className="boxart-image boxart-image-in-padded-container" src={backdrop} alt="" />
                                        <div className="fallback-text-container" aria-hidden="true">
                                            <p className="fallback-text">{video.title}</p>
                                        </div>
                                    </div>
                                    <div className="click-to-change-JAW-indicator">
                                        <div className="bob-jawbone-chevron">
                                            <JawboneChevronSvg className="svg-icon svg-icon-chevron-down" />
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            {/* Title / Action bar */}
                            <div className={styles['video-label']}>
                                <label>{video.title} ({video.year})</label>
                                {
                                    !!video.imdb &&
                                    <button onClick={handleClick} type="button" className={`btn ${styles['btn-details']}`} title="View Details" aria-label="View Details">
                                        <Icon variant="list" className="pointer-none" />
                                    </button>
                                }
                            </div>
                        </div>
                    </div>

                    {/* Progress bar */}
                    {
                        !!currentTime && !!duration &&
                        <div className="progress mt-2">
                            <span className="progress-bar">
                                <span style={{ width: `${(currentTime / duration * 100).toFixed(2)}%` }} role="presentation" className="progress-completed"></span>
                            </span>
                        </div>
                    }

                    {/* Tags */}
                    <div className={styles['video-tags']}>
                        {/* Movies tags are searchable links */}
                        {!('seasons' in video) && video.tags.map(t => <Link key={t} to={`/search?tags=${t}`} className="badge rounded-pill bg-secondary me-1" style={{ textDecoration: "none", color: "unset" }}>{t}</Link>)}
                        {/* TV show tags are just summaries */}
                        {!!('seasons' in video) && video.tags.map(t => <span key={t} className="badge rounded-pill bg-secondary me-1" style={{ textDecoration: "none", color: "unset" }}>{t}</span>)}
                        {
                            !!video.id && msal.user === msal.admin && !('seasons' in video) &&
                            <button onClick={handleEditTags} type="button" className={`btn ${styles['btn-edit-tags']} p-0`} title="Add/Edit tags.">
                                <span className="badge rounded-pill bg-secondary"><Icon variant="pencil-outline" /></span>
                            </button>
                        }
                    </div>
                </div>
            }
        </>
    );
}
