import { Episodic, Movie } from '@types';
import NetflixRowHeader from '../Netflix.Header';
import NetflixTile from '../NetflixTile';
import NetflixRowLoading from './NetflixRowLoading';

type MovieOrEpisodic = Movie | Episodic;

interface NetflixRowStaticProps<T> {
    title: string;
    href?: string;
    loading: boolean;
    count: number;
    videos: T[];
    onClick?: (video: T) => void;
}

/** Component that display a static list of videos in a Netflix-style scrollable row. */
export default function NetflixRowStatic<T extends MovieOrEpisodic>(props: NetflixRowStaticProps<T>) {
    const videos = props.videos;

    return (
        <>
            {props.loading && Array(props.count || 1).fill(0).map((_, i) => <NetflixRowLoading key={i} />)}

            {
                !props.loading &&
                <div className="lolomoRow lolomoRow_title_card css-0" data-list-context="queried" style={{ margin: '1.5vw 0' }}>
                    <NetflixRowHeader title={props.title} href={props.href} />
                    <div className="rowContainer rowContainer_title_card">
                        <div className="ptrack-container">
                            <div className="rowContent slider-hover-trigger-layer">
                                <div className="slider">
                                    <div className="sliderMask showPeek">
                                        <div className="sliderContent row-with-x-columns row-with-x-columns-wrapped">
                                            {
                                                videos.map((video, i) => {
                                                    const key: any = 'key' in video
                                                        ? video.key
                                                        : 'id' in video
                                                            ? video.id
                                                            : i;
                                                    return (
                                                        <NetflixTile<T> key={key}
                                                            index={i}
                                                            model={video}
                                                            onShowVideoDetails={props.onClick}
                                                            data-label={video.title}
                                                        />
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}
