import { msal } from 'config';
import { useEffect, useState } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import Icon from '../Icon';
import styles from './navigator.module.scss';

export default function Navigator () {
    const [showUploader, setShowUploader] = useState(false);
    const location = useLocation();
    const [query, setQuery] = useState(new URLSearchParams(location.search).get('q') || '');
    const navigate = useNavigate();

    useEffect(() => {
        const navbar = document.querySelector('.navbar-nav')!;
        const links = navbar.querySelectorAll<HTMLAnchorElement>('.nav-link');

        for (const link of [...links].filter(l => l.attributes.getNamedItem('href'))) {
            const href = link.attributes.getNamedItem('href')!;

            if (window.location.pathname.startsWith(href.value) && href.value !== '/')
                link.classList.add('fw-bold');
            else if (window.location.pathname === '/' && href.value === '/')
                link.classList.add('fw-bold');
            else
                link.classList.remove('fw-bold');
        }

        setQuery(new URLSearchParams(location.search).get('q') || '');
    }, [location]);

    // Function that handles the search form submit event
    const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        navigate(`/search?q=${query}`);
    };

    const classNames = ["navbar-expand-sm navbar-toggleable-sm border-bottom mb-3", styles['navbar']];

    return (
        <>
            <header className="sticky-top">
                <Navbar className={classNames.join(' ')}>
                    <Navbar.Toggle className="mr-2" />
                    <div className={`w-100 ps-5 pe-5 ${styles['navbar-navs']}`}>
                        <Nav>
                            <Navbar.Collapse>
                                <Link to="/" className="navbar-brand"><img src="/logo192.png" alt="logo" className="app-logo" />movies-site</Link>
                                <Nav className="flex-grow">
                                    <Link to="/" className="nav-link d-flex align-items-center"><Icon variant="home" className="me-1" />Home</Link>
                                    <Link to="/movies" className="nav-link d-flex align-items-center"><Icon variant="film-outline" className="me-1" />Movies</Link>
                                    <Link to="/tv-shows" className="nav-link d-flex align-items-center"><Icon variant="albums-outline" className="me-1" />TV Shows</Link>
                                    {false && <Link to="/my-list" className="nav-link d-flex align-items-center"><Icon variant="list-outline" className="me-1" />My List</Link>}
                                </Nav>
                            </Navbar.Collapse>
                        </Nav>

                        <Nav className={styles['form-search']}>
                            <form onSubmit={handleSearch} className="" autoComplete="off">
                                <div className="input-group">
                                    <input value={query} onChange={({ target }) => setQuery(target.value)} type="text" className="form-control" minLength={2} placeholder="Search..." />
                                    <button className={`btn ${styles['btn-submit']}`} type="submit">
                                        <Icon variant="search" />
                                    </button>
                                </div>
                            </form>
                        </Nav>
                        <Nav>
                            {
                                msal.user === msal.admin &&
                                <button onClick={() => setShowUploader(!showUploader)} type="button" className="btn nav-link d-flex align-items-center ms-auto">
                                    <Icon variant="arrow-up" className="me-1" />
                                    Upload
                                </button>
                            }
                        </Nav>
                    </div>
                </Navbar>
            </header>
        </>
    );
}
