import Numbers from '../numbers';
import NetflixRowHeader from '../Netflix.Header';

/** Component that displays an SVG representation of the specified number. */
export const NumberedSvg = (props: { index: number } & React.HTMLAttributes<SVGElement>) => {
    const Svg = Numbers[props.index];

    return (
        <Svg className={`${props.className} svg-icon svg-icon-rank-${props.index + 1}`} />
    );
};

/** Component for display a list of popular videos. */
export default function NetflixRowPopular() {
    return (
        <div className="lolomoRow lolomoRow_title_card css-0" data-list-context="mostWatched">
            <NetflixRowHeader title="Top 10 Movies in the U.S. Today" href="/brows/genre/9999" />
            <div className="rowContainer rowContainer_title_card" id="row-8">
                <div className="ptrack-container">
                    <div className="rowContent slider-hover-trigger-layer">
                        <div className="slider">
                            <ul className="pagination-indicator">
                                <li className="active" />
                                <li className="" />
                            </ul>
                            <div className="sliderMask showPeek">
                                <div className="sliderContent row-with-x-columns">
                                    <div className="slider-item slider-item-0">
                                        <div className="title-card-container css-0">
                                            <div id="title-card-8-0" className="title-card title-card-top-10">
                                                <div className="ptrack-content"
                                                    data-ui-tracking-context="%7B%22list_id%22:%22GPS_62ECF330BAD21479135FA62F2F9595-F4694D00993BC3-53F0EA92BD_p_1657334489935%22,%22location%22:%22homeScreen%22,%22rank%22:0,%22request_id%22:%220a2a0890-f936-4386-ae9f-25ff30618a99-29635464%22,%22row%22:8,%22track_id%22:262617323,%22video_id%22:81212487,%22image_key%22:%22boxshot%7C22695611-e67a-11ec-97f7-122ac04d6b05%7Cen%7ClDU%22,%22supp_video_id%22:1,%22lolomo_id%22:%22GPS_62ECF330BAD21479135FA62F2F9595_p_1657334489935%22,%22maturityMisMatchEdgy%22:false,%22maturityMisMatchNonEdgy%22:false,%22appView%22:%22boxArt%22,%22usePresentedEvent%22:true%7D"
                                                    data-tracking-uuid="6cf9b755-b2c5-4ba0-b422-6c36724c4643"
                                                >
                                                    <a href="/watch/81212487?tctx=8%2C0%2C%2C%2C%2C%2C%2C%2C"
                                                        aria-label="Girl in the Picture"
                                                        tabIndex={0}
                                                        aria-hidden="false"
                                                        className="slider-refocus"
                                                    >
                                                        <div className="boxart-size-7x10 boxart-container boxart-rounded">
                                                            <NumberedSvg index={0} className="top-10-rank" />
                                                            <img className="boxart-image-in-padded-container"
                                                                src="https://occ-0-90-92.1.nflxso.net/dnm/api/v6/evlCitJPPCVCry0BZlEFb5-QjKc/AAAABe5XsH5uSjGH2j6PVlOVLWtIE8yE-UEQmgZgFFRwDOltdIyDRNr6i5LoR4QHz73xhpVSEwq8pMYEvxrUVyrxjoRSxXvN4sYiQBl-j6RsWMt7EMbhCSuN0HbAXXDQNvY2FMWRWA.jpg?r=d89"
                                                                alt=""
                                                            />
                                                            <div className="fallback-text-container" aria-hidden="true">
                                                                <p className="fallback-text">
                                                                    Girl in the Picture
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="slider-item slider-item-1">
                                        <div className="title-card-container css-0">
                                            <div id="title-card-8-1" className="title-card title-card-top-10">
                                                <div className="ptrack-content"
                                                    data-ui-tracking-context="%7B%22list_id%22:%22GPS_62ECF330BAD21479135FA62F2F9595-F4694D00993BC3-53F0EA92BD_p_1657334489935%22,%22location%22:%22homeScreen%22,%22rank%22:1,%22request_id%22:%220a2a0890-f936-4386-ae9f-25ff30618a99-29635464%22,%22row%22:8,%22track_id%22:262617323,%22video_id%22:81475311,%22image_key%22:%22boxshot%7C4f172750-c802-11ec-b034-0a85da574159%7Cen%7ClDU%22,%22supp_video_id%22:1,%22lolomo_id%22:%22GPS_62ECF330BAD21479135FA62F2F9595_p_1657334489935%22,%22maturityMisMatchEdgy%22:false,%22maturityMisMatchNonEdgy%22:false,%22appView%22:%22boxArt%22,%22usePresentedEvent%22:true%7D"
                                                    data-tracking-uuid="f115cc9d-9b8e-4624-b585-020a25e34e91"
                                                >
                                                    <a href="/watch/81475311?tctx=8%2C1%2C%2C%2C%2C%2C%2C%2C"
                                                        aria-label="Sing 2"
                                                        tabIndex={0}
                                                        aria-hidden="false"
                                                        className="slider-refocus"
                                                    >
                                                        <div className="boxart-size-7x10 boxart-container boxart-rounded">
                                                            <NumberedSvg index={1} className="top-10-rank" />
                                                            <img className="boxart-image-in-padded-container"
                                                                src="https://occ-0-90-92.1.nflxso.net/dnm/api/v6/evlCitJPPCVCry0BZlEFb5-QjKc/AAAABYCJTvuR6_3mqu-YI8oyZEi0TQF6h16UducQX68dKx2xO34nU1cwSe3C8_B0t0fTawjDa3bGdKBt1Ru9oP10px4U2c7NOc41T3lI.jpg?r=14e"
                                                                alt=""
                                                            />
                                                            <div className="fallback-text-container" aria-hidden="true">
                                                                <p className="fallback-text">Sing 2</p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="slider-item slider-item-2">
                                        <div className="title-card-container css-0">
                                            <div id="title-card-8-2" className="title-card title-card-top-10">
                                                <div className="ptrack-content"
                                                    data-ui-tracking-context="%7B%22list_id%22:%22GPS_62ECF330BAD21479135FA62F2F9595-F4694D00993BC3-53F0EA92BD_p_1657334489935%22,%22location%22:%22homeScreen%22,%22rank%22:2,%22request_id%22:%220a2a0890-f936-4386-ae9f-25ff30618a99-29635464%22,%22row%22:8,%22track_id%22:262617323,%22video_id%22:60034551,%22image_key%22:%22boxshot%7Cf491fd60-af8c-11e7-b163-0e3e37cbee0a%7Cen%7ClDU%22,%22supp_video_id%22:1,%22lolomo_id%22:%22GPS_62ECF330BAD21479135FA62F2F9595_p_1657334489935%22,%22maturityMisMatchEdgy%22:false,%22maturityMisMatchNonEdgy%22:false,%22appView%22:%22boxArt%22,%22usePresentedEvent%22:true%7D"
                                                    data-tracking-uuid="09534225-7352-46c9-8cd2-f3278c6cc902"
                                                >
                                                    <a href="/watch/60034551?tctx=8%2C2%2C%2C%2C%2C%2C%2C%2C"
                                                        aria-label="Mean Girls"
                                                        tabIndex={0}
                                                        aria-hidden="false"
                                                        className="slider-refocus"
                                                    >
                                                        <div className="boxart-size-7x10 boxart-container boxart-rounded">
                                                            <NumberedSvg index={2} className="top-10-rank" />
                                                            <img className="boxart-image-in-padded-container"
                                                                src="https://occ-0-90-92.1.nflxso.net/dnm/api/v6/evlCitJPPCVCry0BZlEFb5-QjKc/AAAABfnVPatjM_w1ZKT--3s3ryFd4SL75NtFqlaVo9sasnM1IncvFpQv_LJT5YBipfsYQwxQH6dnBY_c3h_sA5y7JEYigQN2c_kCBPl_.jpg?r=31e"
                                                                alt=""
                                                            />
                                                            <div className="fallback-text-container" aria-hidden="true">
                                                                <p className="fallback-text">
                                                                    Mean Girls
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="slider-item slider-item-3">
                                        <div className="title-card-container css-0">
                                            <div id="title-card-8-3" className="title-card title-card-top-10">
                                                <div className="ptrack-content"
                                                    data-ui-tracking-context="%7B%22list_id%22:%22GPS_62ECF330BAD21479135FA62F2F9595-F4694D00993BC3-53F0EA92BD_p_1657334489935%22,%22location%22:%22homeScreen%22,%22rank%22:3,%22request_id%22:%220a2a0890-f936-4386-ae9f-25ff30618a99-29635464%22,%22row%22:8,%22track_id%22:262617323,%22video_id%22:70213514,%22image_key%22:%22boxshot%7C21c7c9f0-3c16-11ea-ae33-1252a14bc99a%7Cen%7ClDU%22,%22supp_video_id%22:1,%22lolomo_id%22:%22GPS_62ECF330BAD21479135FA62F2F9595_p_1657334489935%22,%22maturityMisMatchEdgy%22:false,%22maturityMisMatchNonEdgy%22:false,%22appView%22:%22boxArt%22,%22usePresentedEvent%22:true%7D"
                                                    data-tracking-uuid="e8e8c1ab-f4cf-4097-9e24-463ccadd6299"
                                                >
                                                    <a href="/watch/70213514?tctx=8%2C3%2C%2C%2C%2C%2C%2C%2C"
                                                        aria-label="The Dark Knight Rises"
                                                        tabIndex={0}
                                                        aria-hidden="false"
                                                        className="slider-refocus"
                                                    >
                                                        <div className="boxart-size-7x10 boxart-container boxart-rounded">
                                                            <NumberedSvg index={3} className="top-10-rank" />
                                                            <img className="boxart-image-in-padded-container"
                                                                src="https://occ-0-90-92.1.nflxso.net/dnm/api/v6/evlCitJPPCVCry0BZlEFb5-QjKc/AAAABcE6DRpp9NdqyrTOTqsX-BwWlF0CXNnN7DC9acLf0ZQBpEPmz3HHEgHA5yjtPqAhAs_htLFGCYkPOrTBEJ32rcBDk_oaNs0cEZr1.jpg?r=8ab"
                                                                alt=""
                                                            />
                                                            <div className="fallback-text-container" aria-hidden="true">
                                                                <p className="fallback-text">
                                                                    The Dark Knight Rises
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="slider-item slider-item-4">
                                        <div className="title-card-container css-0">
                                            <div id="title-card-8-4" className="title-card title-card-top-10">
                                                <div className="ptrack-content"
                                                    data-ui-tracking-context="%7B%22list_id%22:%22GPS_62ECF330BAD21479135FA62F2F9595-F4694D00993BC3-53F0EA92BD_p_1657334489935%22,%22location%22:%22homeScreen%22,%22rank%22:4,%22request_id%22:%220a2a0890-f936-4386-ae9f-25ff30618a99-29635464%22,%22row%22:8,%22track_id%22:262617323,%22video_id%22:81251628,%22image_key%22:%22boxshot%7Cdc082d11-e6c2-11ec-97f7-122ac04d6b05%7Cen%7ClDU%22,%22supp_video_id%22:1,%22lolomo_id%22:%22GPS_62ECF330BAD21479135FA62F2F9595_p_1657334489935%22,%22maturityMisMatchEdgy%22:false,%22maturityMisMatchNonEdgy%22:false,%22appView%22:%22boxArt%22,%22usePresentedEvent%22:true%7D"
                                                    data-tracking-uuid="04c5de7a-7205-425f-866e-f3a7e7215a9f"
                                                >
                                                    <a href="/watch/81251628?tctx=8%2C4%2C%2C%2C%2C%2C%2C%2C"
                                                        aria-label="The Man from Toronto"
                                                        tabIndex={0}
                                                        aria-hidden="false"
                                                        className="slider-refocus"
                                                    >
                                                        <div className="boxart-size-7x10 boxart-container boxart-rounded">
                                                            <NumberedSvg index={4} className="top-10-rank" />
                                                            <img className="boxart-image-in-padded-container"
                                                                src="https://occ-0-90-92.1.nflxso.net/dnm/api/v6/evlCitJPPCVCry0BZlEFb5-QjKc/AAAABU-h5yrXhe5UXnpsuwyVazzQWyZtPsN5RJ_Hk3bMLZxxyTaR7Gpr9tICV3JkPBXM05iccW0impf6JowmtKDThmVXEP5tUZ1EMXttoFlIWk71JMtePhQrvnBag3S65_X9XckSRg.jpg?r=25c"
                                                                alt="" />
                                                            <div className="fallback-text-container" aria-hidden="true">
                                                                <p className="fallback-text">
                                                                    The Man from Toronto
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="slider-item slider-item-5">
                                        <div className="title-card-container css-0">
                                            <div id="title-card-8-5" className="title-card title-card-top-10">
                                                <div className="ptrack-content"
                                                    data-ui-tracking-context="%7B%22list_id%22:%22GPS_62ECF330BAD21479135FA62F2F9595-F4694D00993BC3-53F0EA92BD_p_1657334489935%22,%22location%22:%22homeScreen%22,%22rank%22:5,%22request_id%22:%220a2a0890-f936-4386-ae9f-25ff30618a99-29635464%22,%22row%22:8,%22track_id%22:262617323,%22video_id%22:70075479,%22image_key%22:%22boxshot%7Ccf1b4402-e4df-11e7-9d7b-12094e0b0a84%7Cen%7ClDU%22,%22supp_video_id%22:1,%22lolomo_id%22:%22GPS_62ECF330BAD21479135FA62F2F9595_p_1657334489935%22,%22maturityMisMatchEdgy%22:false,%22maturityMisMatchNonEdgy%22:false,%22appView%22:%22boxArt%22,%22usePresentedEvent%22:true%7D"
                                                    data-tracking-uuid="f5cf6f39-76c1-4f27-92c8-7a945c3ad7ab"
                                                >
                                                    <a href="/watch/70075479?tctx=8%2C5%2C%2C%2C%2C%2C%2C%2C"
                                                        aria-label="Wanted"
                                                        tabIndex={0}
                                                        aria-hidden="false"
                                                        className="slider-refocus"
                                                    >
                                                        <div className="boxart-size-7x10 boxart-container boxart-rounded">
                                                            <NumberedSvg index={5} className="top-10-rank" />
                                                            <img className="boxart-image-in-padded-container"
                                                                src="https://occ-0-90-92.1.nflxso.net/dnm/api/v6/evlCitJPPCVCry0BZlEFb5-QjKc/AAAABbCT7Dn4tlScnDe0pDvJcqL5j_mRSUrvdx7zpJjUhVLTOsvP7Q7pHsTua6vm_ZgqXIeQiDrecbO4ESNqpVW2tG4q65-P2nEHWiSJ.jpg?r=0a6"
                                                                alt=""
                                                            />
                                                            <div className="fallback-text-container" aria-hidden="true">
                                                                <p className="fallback-text">Wanted</p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="slider-item slider-item-6">
                                        <div className="title-card-container css-0">
                                            <div id="title-card-8-6" className="title-card title-card-top-10">
                                                <div className="ptrack-content"
                                                    data-ui-tracking-context="%7B%22list_id%22:%22GPS_62ECF330BAD21479135FA62F2F9595-F4694D00993BC3-53F0EA92BD_p_1657334489935%22,%22location%22:%22homeScreen%22,%22rank%22:6,%22request_id%22:%220a2a0890-f936-4386-ae9f-25ff30618a99-29635464%22,%22row%22:8,%22track_id%22:262617323,%22video_id%22:80240964,%22image_key%22:%22boxshot%7C90bacfd0-398f-11ea-a25f-0a79fb844809%7Cen%7ClDU%22,%22supp_video_id%22:1,%22lolomo_id%22:%22GPS_62ECF330BAD21479135FA62F2F9595_p_1657334489935%22,%22maturityMisMatchEdgy%22:false,%22maturityMisMatchNonEdgy%22:false,%22appView%22:%22boxArt%22,%22usePresentedEvent%22:true%7D"
                                                    data-tracking-uuid="7b94fc8a-d07d-40dd-8058-967db8331583"
                                                >
                                                    <a href="/watch/80240964?tctx=8%2C6%2C%2C%2C%2C%2C%2C%2C"
                                                        aria-label="Leave No Trace"
                                                        tabIndex={-1}
                                                        aria-hidden="true"
                                                        className="slider-refocus"
                                                    >
                                                        <div className="boxart-size-7x10 boxart-container boxart-rounded">
                                                            <NumberedSvg index={6} className="top-10-rank" />
                                                            <img className="boxart-image-in-padded-container"
                                                                src="https://occ-0-90-92.1.nflxso.net/dnm/api/v6/evlCitJPPCVCry0BZlEFb5-QjKc/AAAABa0HgIhvlJzIUdscGl2ccr-ZW4VGLHpB9iQTJNaWF6TLbgYViz6LASSbNDM0WNRCxKeiHYoVY96hGB1Ry9lGhw-GHioGXHMF7AnP.jpg?r=6bd"
                                                                alt=""
                                                            />
                                                            <div className="fallback-text-container" aria-hidden="true">
                                                                <p className="fallback-text">
                                                                    Leave No Trace
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="slider-item slider-item-">
                                        <div className="title-card-container css-0">
                                            <div id="title-card-8-7" className="title-card title-card-top-10">
                                                <div className="ptrack-content"
                                                    data-ui-tracking-context="%7B%22list_id%22:%22GPS_62ECF330BAD21479135FA62F2F9595-F4694D00993BC3-53F0EA92BD_p_1657334489935%22,%22location%22:%22homeScreen%22,%22rank%22:7,%22request_id%22:%220a2a0890-f936-4386-ae9f-25ff30618a99-29635464%22,%22row%22:8,%22track_id%22:262617323,%22video_id%22:80209358,%22image_key%22:%22boxshot%7C65ea5e80-17e9-11ec-9c12-0e42f1aa3627%7Cen%7ClDU%22,%22supp_video_id%22:1,%22lolomo_id%22:%22GPS_62ECF330BAD21479135FA62F2F9595_p_1657334489935%22,%22maturityMisMatchEdgy%22:false,%22maturityMisMatchNonEdgy%22:false,%22appView%22:%22boxArt%22,%22usePresentedEvent%22:true%7D"
                                                    data-tracking-uuid="8acbb6b3-410e-4808-9333-d50ccd525136"
                                                >
                                                    <a href="/watch/80209358?tctx=8%2C7%2C%2C%2C%2C%2C%2C%2C"
                                                        aria-label="Final Score"
                                                        tabIndex={-1}
                                                        aria-hidden="true"
                                                        className="slider-refocus"
                                                    >
                                                        <div className="boxart-size-7x10 boxart-container boxart-rounded">
                                                            <NumberedSvg index={7} className="top-10-rank" />
                                                            <img className="boxart-image-in-padded-container"
                                                                src="https://occ-0-90-92.1.nflxso.net/dnm/api/v6/evlCitJPPCVCry0BZlEFb5-QjKc/AAAABQeB-5uys-veHVkZIVvz3AVxJSehMvfimuBJ6QL_eZPrdEfWXpp2UnQ29gDqnDHBfTHTONuKN01HIpaSrQoywjONHLCeHBzZz_XK.jpg?r=014"
                                                                alt=""
                                                            />
                                                            <div
                                                                className="fallback-text-container"
                                                                aria-hidden="true"
                                                            >
                                                                <p className="fallback-text">
                                                                    Final Score
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="slider-item slider-item-">
                                        <div className="title-card-container css-0">
                                            <div id="title-card-8-8" className="title-card title-card-top-10">
                                                <div className="ptrack-content"
                                                    data-ui-tracking-context="%7B%22list_id%22:%22GPS_62ECF330BAD21479135FA62F2F9595-F4694D00993BC3-53F0EA92BD_p_1657334489935%22,%22location%22:%22homeScreen%22,%22rank%22:8,%22request_id%22:%220a2a0890-f936-4386-ae9f-25ff30618a99-29635464%22,%22row%22:8,%22track_id%22:262617323,%22video_id%22:21303955,%22image_key%22:%22boxshot%7Cb5171670-5a30-11e9-8de8-0a292e297474%7Cen%7ClDU%22,%22supp_video_id%22:1,%22lolomo_id%22:%22GPS_62ECF330BAD21479135FA62F2F9595_p_1657334489935%22,%22maturityMisMatchEdgy%22:false,%22maturityMisMatchNonEdgy%22:false,%22appView%22:%22boxArt%22,%22usePresentedEvent%22:true%7D"
                                                    data-tracking-uuid="fc11355f-da1a-4d31-8e86-84b948148246"
                                                >
                                                    <a href="/watch/21303955?tctx=8%2C8%2C%2C%2C%2C%2C%2C%2C"
                                                        aria-label="Big Daddy"
                                                        tabIndex={-1}
                                                        aria-hidden="true"
                                                        className="slider-refocus"
                                                    >
                                                        <div className="boxart-size-7x10 boxart-container boxart-rounded">
                                                            <NumberedSvg index={8} className="top-10-rank" />
                                                            <img className="boxart-image-in-padded-container"
                                                                src="https://occ-0-90-92.1.nflxso.net/dnm/api/v6/evlCitJPPCVCry0BZlEFb5-QjKc/AAAABTgMmzxV9V5-gNql3nsKAv9p-X2PbFErNX00uP2JFW2m3EW8OHPbrP9ijsvCDTKH0GEGQEBDyN9RmMIAtMwmaczwaM9rrjdpxE5S.jpg?r=825"
                                                                alt=""
                                                            />
                                                            <div className="fallback-text-container" aria-hidden="true">
                                                                <p className="fallback-text">
                                                                    Big Daddy
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <span className="handle handleNext active"
                                tabIndex={0}
                                role="button"
                                aria-label="See more titles"
                            >
                                <b className="indicator-icon icon-rightCaret" />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
