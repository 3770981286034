import { Movie } from '@types';
import { ReactComponent as ImdbSvg } from 'assets/imdb.svg';
import Icon from 'components/Icon';
import { msal } from 'config';
import MovieManager from 'managers/MovieManager';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const moviemanager = new MovieManager();
/** Function for ordering the display of the movies by title. */
const defaultsorting = (l: Movie, r: Movie) => {
    return l.title === r.title
        ? l.year > r.year
            ? 1
            : -1
        : l.title.replace(/^The /g, '') > r.title.replace(/^The /g, '')
            ? 1 : -1;
};
/** Function that converts the resolution quality of a video to bs classes. */
function getQualityBadge(quality?: string): string {
    switch (quality) {
        case '4k': return 'bg-success text-dark ps-3 pe-3';
        case '1080p': return 'bg-info text-dark';
        case '720p': return 'border text-light';
        case '480p': return 'bg-danger text-dark';
        default: return '';
    }
}

/** Component that displays all the movies in a flat list. */
export default function MovieListViewer() {
    const [movies, setMovies] = useState<Movie[]>([]);
    const [sorting, setSorting] = useState('title');
    const sortBy = (func: (m: Movie) => any, key: string) => {
        let sorted = Object.values(movies).sort((l, r) => func(l) === func(r) ? 0 : func(l) > func(r) ? 1 : -1);

        if (sorting.startsWith(key) && !sorting.endsWith('-1'))
            sorted = sorted.reverse();
        if (sorting === key)
            key = `${key}-1`;

        setMovies(sorted);
        setSorting(key);
    };
    const handleVerify = (movie: Movie, verified: boolean) => {
        if (verified)
            localStorage.setItem(movie.id, 'true');
        else
            localStorage.removeItem(movie.id);
    };

    useEffect(() => {
        document.title = 'Browse All Movies - movies-site';

        (async () => {
            const _movies = [];

            for await (const movie of moviemanager.list())
                if (!(movie.id in localStorage))
                    _movies.push(movie);

            document.title = `Browse All ${_movies.length} Movies - movies-site`;
            setMovies(_movies.sort(defaultsorting));
        })();
    }, []);

    return (
        <div className="container">
            <table className="table text-light table-hover">
                <thead>
                    <tr>
                        {msal.user === msal.admin && <th className="border-0"></th>}
                        <th className="border-0"></th>
                        <th onClick={() => sortBy(m => m.title.replace(/^The /g, ''), 'title')} className="border-0 clickable">Title</th>
                        <th onClick={() => sortBy(m => m.year * -1, 'year')} className="border-0 clickable">Year</th>
                        <th className="border-0 d-none d-sm-table-cell">IMDb</th>
                        <th onClick={() => sortBy(m => m.mpaa, 'mpaa')} className="border-0 clickable">MPAA</th>
                        <th onClick={() => sortBy(m => Number(m.runtime?.match(/\d+(?=h)/g)?.[0]) * 60 + Number(m.runtime?.match(/\d+(?=m)/g)?.[0] || 0), 'runtime')} className="border-0 clickable">Runtime</th>
                        <th className="border-0 d-none d-sm-table-cell">Subs</th>
                        <th onClick={() => sortBy(m => (m.quality === '4k' ? 4000 : parseInt(m.quality ?? '')) * -1, 'quality')} className="border-0 clickable d-none d-sm-table-cell">Quality</th>
                        <th className="border-0 d-none d-sm-table-cell">Tags</th>
                        <th onClick={() => sortBy(m => m.backdrops.length, 'backdrops')} className="border-0 clickable d-none d-sm-table-cell">Backdrops</th>
                    </tr>
                </thead>
                <tbody>
                    {!movies.length && <tr><td colSpan={99} className="font-italic text-center border-0">Loading...</td></tr>}
                    {
                        movies.map(m => (
                                <tr key={m.id}>
                                    {msal.user === msal.admin && <td><input defaultChecked={m.id in localStorage} onChange={({ target }) => handleVerify(m, target.checked)} type="checkbox" className="form-check" /></td>}
                                    <td className="text-center">
                                        <Link to={`/movies/${m.id}`} className="btn btn-sm p-0 text-light"><Icon variant="play" /></Link>
                                    </td>
                                    <td>{m.title}</td>
                                    <td>{m.year}</td>
                                    <td className="d-none d-sm-table-cell">
                                        {!m.imdb && '-'}
                                        {
                                            !!m.imdb &&
                                            <a href={`https://imdb.com/title/${m.imdb}/`} className="text-light">
                                                <span className="d-flex align-items-center mt-1">
                                                    <ImdbSvg />
                                                    <Icon variant="open-outline" className="ms-1" />
                                                </span>
                                            </a>
                                        }
                                    </td>
                                    <td>{m.mpaa || '-'}</td>
                                    <td>{m.runtime || '-'}</td>
                                    <td className="d-none d-sm-table-cell"><input type="checkbox" checked={m.subs} disabled /></td>
                                    <td className="d-none d-sm-table-cell"><span className={`badge ${getQualityBadge(m.quality)}`}>{m.quality || '-'}</span></td>
                                    <td className="d-none d-sm-table-cell">
                                        {m.tags.map(t => <Link key={t} to={`/search?tags=${t}`} className="badge bg-secondary me-2" style={{ textDecoration: 'none', color: 'unset' }}>{t}</Link>)}
                                    </td>
                                    <td className="d-none d-sm-table-cell">{m.backdrops.length}</td>
                                </tr>
                            )
                        )
                    }
                </tbody>
            </table>
        </div>
    );
}
