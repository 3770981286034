import { Episode, Season } from '@types';
import { ReactComponent as ImdbSvg } from 'assets/imdb.svg';
import Icon from 'components/Icon';
import { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import styles from './episodic.module.scss';

interface EpsiodicListerProps extends React.HTMLAttributes<HTMLDivElement> {
    model: Season[];
    onClickEpisode?: (ep: Episode) => void;
}

/** Component for displaying all the episodes grouped by season for a single episodic series. */
export default function EpisodicLister(props: EpsiodicListerProps) {
    const location = useLocation();
    const id = location.pathname.split('/').at(-1);
    const { model: seasons, onClickEpisode, className, ...rest } = props;
    const refLister = useRef<HTMLDivElement>(null);
    let seasonIndexOfPlayingEpisode = 0;

    if (location.pathname.match(/\btv-shows\b\/\w+/) && id)
        seasonIndexOfPlayingEpisode = seasons.findIndex(s => s.episodes.find(e => e.id === id));

    const [index, setIndex] = useState(seasonIndexOfPlayingEpisode);

    useEffect(() => {
        for (const season of seasons ?? []) {
            for (const ep of season.episodes) {
                if (ep.poster) {
                    const $e = document.querySelector<HTMLElement>(`[data-episode="${ep.id}"].${styles['episode-link']}`);
                    if ($e)
                        $e.style['backgroundImage'] = `url(${ep.poster.replace(/@@._V1_.jpg$/g, '@@._V1_QL100_UX200_CR0_.jpg')})`;
                }
            }
        }

        const $container = refLister.current?.querySelector(`${styles['seasons-lister-container']}:not([hidden])`);

        if ($container) {
            const $selected = $container.querySelector<HTMLElement>(`${styles['seasons-lister-episode-tile']}.selected`);

            if ($selected)
                $container.scrollTop = $selected.offsetTop;
        }
    }, [seasons]);

    const classNames = [className, styles['seasons-lister']].filter(s => s);

    if (className)
        classNames.push(className);

    // Callback for handling the user clicking on an episode link
    const handleClick = (e: React.MouseEvent<HTMLAnchorElement>, ep: Episode) => {
        onClickEpisode?.(ep);
    };

    return (
        <div ref={refLister} {...rest} className={classNames.join(' ')}>
            {
                !!seasons?.length &&
                <ul className={styles['seasons-lister-seasons']}>
                    {seasons.map((s, i) => {
                        const selected = i === index;

                        return (
                            <li key={s.id}>
                                <button onClick={() => setIndex(i)} type="button" className={`btn ps-4 ${selected ? styles['active'] : ''}`}>
                                    Season {s.id}
                                </button>
                            </li>
                        );
                    })}
                </ul>
            }

            {
                seasons?.map((season, i) => {
                    const years = [...new Set(season.episodes.map(e => e.airdate?.match(/\d{4}/g)?.[0]))].filter(y => y);
                    const from = years[0];
                    const to = years.at(-1);
                    const timeline = years.length > 1 ? `${from}-${to}` : from;
                    const selected = i === index;

                    return (
                        <div key={season.id} className={`container ${styles['seasons-lister-container']}`} hidden={!selected}>
                            <h3 className={styles['seasons-lister-header']}>Season {season.id} {!!timeline && `(${timeline})`}</h3>
                            <div className={styles['seasons-lister-body']}>
                                {
                                    season.episodes.map(episode => {
                                        return (
                                            <EpisodicListerRowForEpisode key={episode.id}
                                                episode={episode}
                                                onClick={e => handleClick(e, episode)}
                                            />
                                        );
                                    })
                                }
                            </div>
                        </div>
                    );
                })
            }
        </div>
    );
}

interface EpisodeRowProps {
    episode: Episode;
    onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}

/** Component for displaying a single episode from an episodic series. */
function EpisodicListerRowForEpisode(props: EpisodeRowProps) {
    const { episode, onClick } = props;
    const selected = episode.id === window.location.pathname.split('/').at(-1);
    const classList = ['p-2 ps-3 pe-3'];
    const hours = Number(episode.runtime?.match(/\d+(?=h)/g)?.[0]) || 0;
    const minutes = Number(episode.runtime?.match(/\d+(?=m)/g)?.[0]) || 0;
    const duration = hours * 60 * 60 + minutes * 60;
    let currentTime = 0;

    if (`${episode.id}:meta` in window.localStorage) {
        const meta = JSON.parse(window.localStorage[`${episode.id}:meta`]);
        currentTime = Number(meta.currentTime);
    }

    if (selected)
        classList.push('bg-light text-dark selected');

    return (
        <Row key={episode.id} className={`border border-secondary mb-3 ${styles['seasons-lister-episode-tile']}`}>
            <Col className={classList.join(' ')}>
                <Row>
                    <Col xs={2}>
                        <div className="d-flex align-items-center justify-content-center w-100 h-100">
                            <div data-episode={episode.id} className={styles['episode-link']}>
                                <Link onClick={onClick} to={`/tv-shows/${episode.id}`} className="btn p-0 fs-1" title={`Play ${episode.title}`}>
                                    <Icon variant="play-outline" />
                                </Link>
                                <span className={`${styles['episode-ep']} text-light small`}>S{episode.season.toString().padStart(2, '0')} E{episode.episode.toString().padStart(2, '0')}</span>

                                {/* Progress bar */}
                                {
                                    !!currentTime && !!duration &&
                                    <div className={`progress ${styles['progress']}`}>
                                        <span className="progress-bar">
                                            <span style={{ width: `${(currentTime / duration * 100).toFixed(2)}%` }} role="presentation" className="progress-completed"></span>
                                        </span>
                                    </div>
                                }
                            </div>
                        </div>
                    </Col>
                    <Col xs={10}>
                        <div className="mt-2 position-relative">
                            <h5 className={styles['episode-title']}>{episode.title}</h5>
                            <p>{episode.storyline}</p>

                            {
                                !!episode.imdb &&
                                <a href={`https://imdb.com/title/${episode.imdb}/`} className={`${styles['episode-imdb-link']}`} target="_blank" rel="noreferrer">
                                    <span className="d-flex align-items-center mt-1">
                                        <ImdbSvg />
                                        {selected && <Icon variant="open-outline" className="ms-1 text-dark" />}
                                        {!selected && <Icon variant="open-outline" className="ms-1 text-light" />}
                                    </span>
                                </a>
                            }
                        </div>
                        <div className="small">
                            <span className={"badge border me-2" + (selected ? ' text-dark border-dark' : '')}>{episode.airdate}</span>
                            <span className={"badge border me-2" + (selected ? ' text-dark border-dark' : '')}>{episode.runtime}</span>
                            <span className={"badge border me-2" + (selected ? ' text-dark border-dark' : '')}>{episode.mpaa}</span>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}
