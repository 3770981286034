import { BlobServiceClient } from '@azure/storage-blob';
import { Subtitle } from '@types';
import config, { msal } from 'config';

const blobServiceClient = new BlobServiceClient(`https://${config.blobAccount}.blob.core.windows.net${msal.sas}`);

export default class SubtitleManager {
    /** Function that retrieves the url of subtitles for a video if it has captioning. */
    async get(id: string): Promise<Subtitle|undefined> {
        const container = blobServiceClient.getContainerClient('subtitles');
        const blobClient = container.getBlobClient(id);

        if (await blobClient.exists())
            return { url: blobClient.url };
    }
}
(window as any).subtitleManager = new SubtitleManager();
