import { TILES_PER_ROW } from './config';

/** Component used to display a spinner while the list of videos is loading. */
export default function NetflixRowLoading() {
    return (
        <div className="lolomoRow lolomoRow_title_card lolomoPreview">
            <div className="rowHeader">
                <span className="rowTitle">&nbsp;</span>
            </div>
            <div className="rowContent">
                <div className="slider">
                    {
                        Array(TILES_PER_ROW)
                            .fill(0)
                            .map((_, i) => {
                                return (
                                    <div key={i} className="smallTitleCard loadingTitle">
                                        <div className="ratio-16x9 pulsate" style={{ animationDelay: `${i * 0.2}s` }} />
                                    </div>
                                );
                            })
                    }
                </div>
            </div>
        </div>
    );
}
