import { Episode, Episodic } from '@types';
import EpisodicManager from 'managers/EpisodicManager';
import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import VideoPlayer from '../VideoPlayer';
import EpisodicLister from './EpisodicLister';

const episodicmanager = new EpisodicManager();

const EMPTY_EPISODE: Episode = {
    id: '',
    series: '',
    season: 0,
    episode: 0,
    title: '',
    imdb: '',
    createdOn: new Date(1970, 0, 1),
    airdate: '',
    tags: [],
    poster: '',
    mpaa: '',
    runtime: ''
};

/** Component for playing an episode from an episodic series. */
export default function EpisodicPlayer() {
    const location = useLocation();
    const id = location.pathname.split('/').at(-1)!;
    const [episode, setEpisode] = useState<Episode>(EMPTY_EPISODE);
    const [series, setSeries] = useState<Episodic>();
    const navigate = useNavigate();

    useEffect(() => {
        (async function() {
            const episode = await episodicmanager.get(id)
            setEpisode(episode);
            document.title = `Watching ${episode.series} - ${episode.title} - movies-site`;

            if (!series) {
                const s = await episodicmanager.getSeries(episode.series);
                setSeries(s);
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);
    const handleEpisodeChange = (e: Episode) => {
        setEpisode(e);
        document.querySelector('nav')?.scrollIntoView();
    };
    const handleEnded = () => {
        if (!series)
            return;

        for (const season of series.seasons) {
            for (const [ep, i] of season.episodes.map<[Episode, number]>((e, i) => [e, i])) {
                if (ep.id === episode.id) {
                    if (season.episodes[i + 1])
                        navigate(`/tv-shows/${season.episodes[i + 1].id}`);
                }
            }
        }
    };

    const title = episode.id ? `${episode.series} ${episode.season}.${episode.episode} - ${episode.title}` : '';

    return (
        <div>
            <VideoPlayer key={episode.id}
                title={title}
                video={episode}
                onEnded={handleEnded}
            />

            {
                !!series &&
                <Container>
                    <h3 className="display-4 mt-4 mb-4">{series.seasons.length} Seasons & {series.seasons.reduce((sum, s) => sum + s.episodes.length, 0)} Episodes</h3>

                    <EpisodicLister model={series.seasons}
                        onClickEpisode={handleEpisodeChange}
                        className="mb-5"
                    />
                </Container>
            }
        </div>
    );
}
