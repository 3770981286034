import { ReactComponent as One } from './one.svg';
import { ReactComponent as Two } from './two.svg';
import { ReactComponent as Three } from './three.svg';
import { ReactComponent as Four } from './four.svg';
import { ReactComponent as Five } from './five.svg';
import { ReactComponent as Six } from './six.svg';
import { ReactComponent as Seven } from './seven.svg';
import { ReactComponent as Eight } from './eight.svg';
import { ReactComponent as Nine } from './nine.svg';
import { ReactComponent as Ten } from './ten.svg';

type SVGNumberElementConstructor = (props: React.HTMLAttributes<SVGElement>) => JSX.Element;

const Numbers: SVGNumberElementConstructor[] = [
    (props) => <One {...props} />,
    (props) => <Two {...props} />,
    (props) => <Three {...props} />,
    (props) => <Four {...props} />,
    (props) => <Five {...props} />,
    (props) => <Six {...props} />,
    (props) => <Seven {...props} />,
    (props) => <Eight {...props} />,
    (props) => <Nine {...props} />,
    (props) => <Ten {...props} />
];

export default Numbers;
