import { Episodic } from '@types';
import Netflix from 'components/netflix';
import EpisodicManager from 'managers/EpisodicManager';
import { useEffect, useState } from 'react';
import EpisodicViewer from './EpisodicDetailViewer';
import styles from './episodic.module.scss';

const MS_IN_2_WEEKS = 1000 * 60 * 60 * 24 * 14;
const episodicmanager = new EpisodicManager();

type EpisodicDetail = Episodic & {
    lastReleaseDate: Date;
    id: string;
    tags: string[];
    isNewOrTrending?: boolean;
    year?: string;
}

/** Component for browsing the list of currently available episodic series. */
export default function EpisodicBrowser() {
    const [loading, setLoading] = useState(true);
    const [episodics, setEpisodics] = useState<Episodic[]>([]);
    const [showEpisodicViewer, setShowEpisodicViewer] = useState(false);
    const [model, setModel] = useState<Episodic>();

    useEffect(() => {
        document.title = 'Browse TV Shows - movies-site';
        let cancelled = false;

        (async () => {
            const _episodics: { [title: string]: EpisodicDetail } = {};

            for await (const ep of episodicmanager.list())
                _episodics[ep.title] = { ...ep, id: '', lastReleaseDate: new Date(ep.seasons.at(-1)?.episodes.at(-1)?.airdate ?? 0), tags: [] };

            const sorted = Object.values(_episodics).sort((l, r) => l.lastReleaseDate === r.lastReleaseDate ? 0 : l.lastReleaseDate > r.lastReleaseDate ? -1 : 1);

            for (const episodic of sorted) {
                episodic.id = episodic.seasons[0].episodes[0].id;
                episodic.tags = [`${episodic.seasons.length} season(s)`, `${episodic.seasons.reduce((sum, s) => sum + s.episodes.length, 0)} episode(s)`];
                episodic.isNewOrTrending = episodic.seasons.map(s => s.episodes.map(e => e.createdOn)).flat().sort().at(-1)! >= new Date(Date.now() - MS_IN_2_WEEKS);

                if (!episodic.backdrops.length)
                    episodic.backdrops = [episodic.poster];

                const first = Number(episodic.seasons[0].episodes[0].airdate?.match(/\d{4}/));
                const last = episodic.lastReleaseDate.getFullYear();

                if (first === last)
                    episodic.year = first.toString();
                else
                    episodic.year = `${first}-${last}`;
            }

            document.title = `Browse ${Object.keys(_episodics).length} TV Shows - movies-site`;

            if (!cancelled) {
                setEpisodics(sorted);
                setLoading(false);
            }
        })();

        return () => cancelled = true as any;
    }, []);

    // Callback for showing the details of the user-selected episodic series
    const handleClick = (series: Episodic) => {
        setShowEpisodicViewer(true);
        setModel(series);
    };

    return (
        <>
            <div className={styles['episodics-container']}>
                <h6 className={styles['episodics-counter']}>{episodics.length} total</h6>

                <Netflix.Static key={episodics.length}
                    title="Episodic Series"
                    loading={loading}
                    videos={episodics}
                    onClick={handleClick}
                    count={3}
                />
            </div>

            <EpisodicViewer show={showEpisodicViewer}
                model={model}
                onHide={() => {
                    setShowEpisodicViewer(false);
                    setModel(undefined);
                }}
            />
        </>
    );
}
