import { useMsal } from '@azure/msal-react';
import config from 'config';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'utils';
import styles from './login.module.scss';

interface LoginProps {
    referrer: any;
}

/** Component that prompts the user to log in. */
export default function Login(props: LoginProps) {
    const [error, setError] = useState<{ errorMessage: string }>();
    const { instance, } = useMsal();
    const mobile = isMobile();

    const navigate = useNavigate();
    const referrer = props.referrer;

    const handleLogin = () => {
        if (mobile) {
            instance.loginRedirect({ scopes: ['User.Read'] })
                .then(() => navigate(referrer, { replace: true }))
                .catch(setError);
        }
        else {
            instance.loginPopup({ scopes: ['User.Read'] })
                .then(() => navigate(referrer, { replace: true }))
                .catch(setError);
        }
    };

    if (config.sha)
        console.log(`Build No. ${config.sha.substring(0, 8)}`);

    return (
        <div className="login-bg">
            <div className={styles["login-island"]}>
                <div>
                    {!!error && <div className="text-danger text-center p-3" role="alert">{error.errorMessage}</div>}

                    <h2 className="display-1 text-center">Movies</h2>
                    <hr className="mb-4 mt-3" />
                    <div className="d-flex justify-content-center">
                        <button onClick={handleLogin} type="button" className="btn nav-link d-flex align-items-center" autoFocus>
                            <img alt="Microsoft Logo" src="https://docs.microsoft.com/favicon.ico" className={styles['microsoft-logo']} />Sign in
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
