import MovieManager from 'managers/MovieManager';
import { useEffect, useState } from 'react';
import VideoPlayer from '../VideoPlayer';
import { Movie } from '@types';

const moviemanager = new MovieManager();

const EMPTY_MOVIE: Movie = {
    id: '',
    title: '',
    year: 1970,
    createdOn: new Date(1970, 0, 1),
    tags: [],
    imdb: '',
    poster: '',
    mpaa: '',
    runtime: '',
    subs: false,
    backdrops: []
};

/** Component used to play a movie. */
export default function MoviePlayer() {
    const id = window.location.pathname.split('/').at(-1)!;
    const [movie, setMovie] = useState<Movie>(EMPTY_MOVIE);
    const title = `${movie.title} (${movie.year})`;

    useEffect(() => {
        let cancelled = false;

        (async () => {
            const movie = await moviemanager.get(id);

            if (!cancelled) {
                document.title = `Watching ${movie.title} - movies-site`;
                setMovie(movie);
            }
        })();

        return () => cancelled = true as any;
    }, [id]);

    return (
        <div>
            <VideoPlayer key={movie.id}
                title={title}
                video={movie}
            />
        </div>
    );
}
