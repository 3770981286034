import { Configuration as MsalConfiguration } from '@azure/msal-browser';

const config = {
    blobAccount: process.env.REACT_APP_BLOB_ACCOUNT,
    sha: process.env.REACT_APP_BUILD_VERSION,
    imdbApiKey: process.env.REACT_APP_IMDB_API_KEY
};

export default config;

type Configuration = MsalConfiguration & { sas: string, user?: string, admin: string };

const msal = {
    sas: process.env.REACT_APP_MSAL_SAS,
    /** Email address of the app administrator */
    admin: process.env.REACT_APP_MSAL_ADMIN,
    auth: {
        clientId: process.env.REACT_APP_MSAL_CLIENT_ID,
        authority: `https://login.microsoftonline.com/${process.env.REACT_APP_MSAL_TENANT_ID}`,
        redirectUri: window.location.origin,
        postLogoutRedirectUri: `${window.location.origin}/login`
    },
    cache: {
        cacheLocation: 'sessionStorage'
    },
    user: null
} as any as Configuration;

Object.defineProperty(msal, 'user', { get: () => JSON.parse(Object.values(sessionStorage).find(v => v.includes('"username"')))?.username });

export { msal };
