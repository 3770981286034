import { Movie } from '@types';
import MovieDetailViewer from 'components/movies/MovieDetailViewer';
import MovieManager from 'managers/MovieManager';
import { useEffect, useState } from 'react';
import './netflix.css';
import { NetflixRowGenre, NetflixRowLoading, NetflixRowPopular, NetflixRowQueried, NetflixRowStatic } from './rows';

const movieManager = new MovieManager();
const sorting = (l: Movie, r: Movie) => l.year === r.year
    ? l.title > r.title ? 1 : -1
    : l.year > r.year ? -1 : 1;

interface NetflixCategory {
    title: string;
    filter: (movie: Movie) => boolean;
    sorting: (left: Movie, right: Movie) => number;
    href?: string;
}

function runtimeToSeconds(runtime: string) {
    const [hours, minutes] = runtime.match(/\d+/g)!;
    return (Number(hours) * 60 * 60) + (Number(minutes) * 60);
}

const categories: NetflixCategory[] = [
    { title: 'New & Trending', filter: m => true, sorting: (l, r) => l.createdOn?.valueOf() === r.createdOn?.valueOf() ? 0 : l.createdOn?.valueOf() < r.createdOn?.valueOf() ? 1 : -1, href: '/search?tags=new-and-trending' },
    { title: 'Marvel Cinematic Universe', filter: m => m.tags.includes('mcu'), sorting, href: '/search?tags=mcu' },
    { title: 'DC Extended Universe', filter: m => m.tags.includes('dceu'), sorting, href: '/search?tags=dceu' },
    { title: 'Family Animation', filter: m => m.tags.includes('family') && m.tags.includes('animation'), sorting, href: '/search?tags=family&tags=animation' },
    { title: 'Thrillers', filter: m => m.tags.includes('action') && m.tags.includes('thriller'), sorting, href: '/search?tags=action' },
    { title: 'Bio-Pics', filter: m => m.tags.includes('biography'), sorting, href: '/search?tags=biography' }
];

const Netflix = {
    Loading: NetflixRowLoading,
    Genre: NetflixRowGenre,
    Popular: NetflixRowPopular,
    Static: NetflixRowStatic,
    Queried: NetflixRowQueried
};

export { Netflix };

/** Component used to display a series of scrollable rows Netflix-style. */
export default function NetflixBrowser() {
    const [model, setModel] = useState<Movie>();
    const [showMovieViewer, setShowMovieViewer] = useState(false);

    useEffect(() => {
        document.title = 'Browse Movies - movies-site';

        (async () => {
            const count = await movieManager.count();
            document.title = `Browse ${count} Movies - movies-site`;
        })();
    }, []);
    const handleClick = (movie: Movie) => {
        setModel(movie);
        setShowMovieViewer(true);
    };

    return (
        <>
            <div className="netflix-sans-font-loaded">
                <div dir="ltr">
                    <div>
                        <div className="bd dark-background" lang="en-US">
                            <div id="main-view" role="main" className="mainView">
                                <div className="lolomo is-fullbleed">
                                    <Netflix.Genre id="continue-watching"
                                        title="Continue Watching"
                                        filter={m => {
                                            if (`${m.id}:meta` in window.localStorage) {
                                                const meta = JSON.parse(window.localStorage[`${m.id}:meta`]);
                                                // Judging it to *not* be a video they stopped part-way thru if it's not in the below window
                                                return (meta.currentTime) < (runtimeToSeconds(m.runtime) * 0.97)
                                                    && (meta.currentTime) > (runtimeToSeconds(m.runtime) * 0.01);
                                            }
                                            else
                                                return false;
                                        }}
                                        sorting={(l, r) => {
                                            if (!(`${l.id}:meta` in window.localStorage) || !(`${r.id}:meta` in window.localStorage))
                                                return 0;
                                            else {
                                                const [lmeta, rmeta] = [
                                                    JSON.parse(window.localStorage[`${l.id}:meta`]),
                                                    JSON.parse(window.localStorage[`${r.id}:meta`])
                                                ];
                                                return new Date(lmeta.lastWatched) < new Date(rmeta.lastWatched) ? -1 : 1;
                                            }
                                        }}
                                        onShowVideoDetails={handleClick}
                                        limit={99}
                                    />
                                    {
                                        categories.map(c => {
                                            return (
                                                <Netflix.Genre key={c.title}
                                                    id={c.title.toLocaleLowerCase().replace(/\s/g, '-')}
                                                    title={c.title}
                                                    filter={c.filter}
                                                    sorting={c.sorting}
                                                    limit={24}
                                                    href={c.href}
                                                    onShowVideoDetails={handleClick}
                                                    className="mb-5"
                                                />
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <MovieDetailViewer show={showMovieViewer}
                model={model}
                onHide={() => {
                    setShowMovieViewer(false);
                    setModel(undefined);
                }}
            />
        </>
    );
}
