/* eslint-disable no-sequences */
import { BlobServiceClient, BlockBlobUploadOptions } from '@azure/storage-blob';
import { Movie } from '@types';
import config, { msal } from 'config';
import * as fuzzy from 'fuzzy';
import { v4 as uuidv4 } from 'uuid';

const blobServiceClient = new BlobServiceClient(`https://${config.blobAccount}.blob.core.windows.net${msal.sas}`);

/**
 * Function that calculates video quality (resolution) from content length and runtime.
 * @param contentLength - MP4 content length
 * @param runtime - Hours and minutes of the video
 */
function contentLengthToQuality(contentLength: number, runtime: string): [number, string] {
    const hours = Number(runtime.match(/\d+(?=h)/g)?.[0]);
    const minutes = Number(runtime.match(/\d+(?=m)/g)?.[0]);
    const totalSeconds = hours * 60 * 60 + minutes * 60;
    const bitrate = contentLength / (totalSeconds * 0.0075) / 16;

    if (typeof hours === 'number' && typeof minutes === 'number') {
        const kbps = Number((bitrate / 1000).toFixed(2));

        if (kbps > 8000)
            return [kbps, '4k'];
        else if (kbps > 4000)
            return [kbps, '1080p'];
        else if (kbps > 1200)
            return [kbps, '720p'];
        else
            return [kbps, '480p'];
    }
    else
        return [-1, 'Unknown'];
}

export default class MovieManager {
    static #cache: { [id: string]: Movie } = {};
    static #xhr: Promise<Movie[]> | undefined = undefined;

    /**
     * Function that asynchronously returns a count of all the movies available.
     */
    async count(): Promise<number> {
        if (MovieManager.#xhr)
            return await MovieManager.#xhr.then(movies => movies.length);

        const container = blobServiceClient.getContainerClient('movies');
        const blobs = container.listBlobsFlat();
        let count = 0;

        while (await blobs.next().then(r => !r.done))
            ++count;

        return count;
    }

    /**
     * Function that asynchronously returns a list of all the movies available.
     */
    async* list(): AsyncIterable<Movie> {
        const begin = Date.now();
        let i = 0;

        if (!MovieManager.#xhr) {
            MovieManager.#xhr = new Promise(async resolve => {
                const container = blobServiceClient.getContainerClient('movies');
                const blobs = container.listBlobsFlat({ includeMetadata: true, includeTags: true });
                const movies = [];

                for await (const blob of blobs) {
                    const tags = blob.tags!;
                    const metadata = blob.metadata!;

                    const movie: Movie = {
                        id: blob.name,
                        title: unescape(tags.title.replace(/=/g, '%')),
                        year: Number(metadata.year),
                        createdOn: blob.properties.createdOn!,
                        tags: tags.tags?.split(' ').sort() || [],
                        imdb: metadata.imdb,
                        poster: metadata.poster,
                        contentType: blob.properties.contentType,
                        mpaa: metadata.mpaa,
                        runtime: metadata.runtime,
                        storyline: metadata.storyline ? decodeURIComponent(metadata.storyline.replace(/=/g, '%')) : undefined,
                        subs: !!metadata.captioning,
                        backdrops: Object.entries(metadata)
                            .filter(([k]) => k.startsWith('backdrop_'))
                            .map(([_, v]) => {
                                if (new URL(v).hostname === `${config.blobAccount}.blob.core.windows.net`)
                                    return v + msal.sas;
                                else
                                    return v;
                            })
                    };

                    if (movie.runtime) {
                        const [kbps, quality] = contentLengthToQuality(blob.properties.contentLength!, movie.runtime);;
                        movie.kbps = kbps;
                        movie.quality = quality;
                    }

                    MovieManager.#cache[movie.id] = movie;
                    movies.push(movie);
                }

                resolve(movies);
            });
        }

        const movies = await MovieManager.#xhr;
        for (const movie of movies)
            yield (++i, Promise.resolve(movie));

        (window as any).movies = movies;
        console.log(`Took ${(Date.now() - begin) / 1000}s to list ${i} movies.`);

        // TODO: One time fix
        for (const movie of movies) {
            if (`${movie.id}:currentTime` in window.localStorage) {
                window.localStorage[`${movie.id}:meta`] = JSON.stringify({ currentTime: Number(window.localStorage[`${movie.id}:currentTime`]), lastWatched: 0 });
                window.localStorage.removeItem(`${movie.id}:currentTime`);
            }
        }
    }

    /**
     * Uploads the movie to azure blob.
     * @param onProgress - callback when there is upload progress
     */
    async upload({ title, year, tags, file, poster }: Movie & { file: File }, onProgress: (progress: number) => void): Promise<{ id: string, status: number, ok: boolean }> {
        const container = blobServiceClient.getContainerClient('movies');
        const content = await file.arrayBuffer();
        const id = uuidv4();
        const options: BlockBlobUploadOptions = {
            blobHTTPHeaders: {
                blobContentType: file.type
            },
            metadata: { poster, year: year.toString() },
            tags: {
                title,
                ...tags.reduce((map, t) => (map[t] = '', map), {} as { [tag: string]: string })
            },
            onProgress: ({ loadedBytes }) => onProgress?.(Number((loadedBytes / content.byteLength).toPrecision(2)) * 1)
        };

        const blockBlobClient = container.getBlockBlobClient(id);
        const response = await blockBlobClient.upload(content, content.byteLength, options);

        return {
            id,
            status: response._response.status,
            ok: response._response.status >= 200 && response._response.status <= 299
        };
    }

    /**
     * Updates the movies' values.
     * @param id - Unique identifier
     * @param values - Values to update
     */
    async update(id: string, values: { [field: string]: any }): Promise<Movie> {
        const container = blobServiceClient.getContainerClient('movies');
        const blobClient = container.getBlobClient(id);
        const tags = await blobClient.getTags().then(({ tags }) => tags);
        const metadata = await blobClient.getProperties().then(r => r.metadata!);
        const xhrs = [];

        if (values.year && Number.isInteger(values.year))
            metadata['year'] = values.year.toString();
        if (values.imdb)
            metadata['imdb'] = values.imdb;
        if (values.mpaa)
            metadata['mpaa'] = values.mpaa;
        if (values.runtime)
            metadata['runtime'] = values.runtime;
        if (values.mpaa)
            metadata['storyline'] = escape(values.storyline).replace(/(%20)/g, ' ').replace('%', '=');
        if (values.poster)
            metadata['poster'] = values.poster;
        if (values.backdrops && Array.isArray(values.backdrops))
            values.backdrops.forEach((b, i) => metadata[`backdrop_${i}`] = b);
        if (typeof values.captioning !== 'undefined')
            metadata['captioning'] = values.captioning.toString();

        if (values.title)
            tags['title'] = escape(values.title).replace(/(%20)/g, ' ').replace('%', '=');
        if (values.tags && Array.isArray(values.tags) && values.tags.length)
            tags['tags'] = values.tags?.join(' ');

        if (Object.keys(tags).length)
            xhrs.push(blobClient.setTags(tags));
        if (Object.keys(metadata).length)
            xhrs.push(blobClient.setMetadata(metadata));

        await Promise.all(xhrs);

        return MovieManager.#cache[id] = await this.get(id);
    }

    /**
     * Returns the movie content
     * @param id - Id of the movie
     */
    async get(id: string): Promise<Movie> {
        const container = blobServiceClient.getContainerClient('movies');
        const blobClient = container.getBlobClient(id);
        console.log(`[${id}] blobClient.getTags():`, await blobClient.getTags());
        const { tags } = await blobClient.getTags();
        const title = tags['title'];
        const properties = await blobClient.getProperties();
        const { year, imdb, poster, mpaa, runtime, storyline, captioning } = properties.metadata!;

        const movie: Movie = {
            id: blobClient.name,
            title: unescape(title.replace(/=/g, '%')),
            year: Number(year),
            createdOn: properties.createdOn!,
            imdb,
            poster,
            url: blobClient.url,
            contentType: properties.contentType,
            tags: tags['tags']?.split(' ').sort() || [],
            mpaa,
            runtime,
            storyline: storyline ? decodeURIComponent(storyline.replace(/=/g, '%')) : undefined,
            subs: !!captioning,
            backdrops: Object.entries(properties.metadata!)
                .filter(([k]) => k.startsWith('backdrop_'))
                .map(([_, v]) => {
                    if (new URL(v).hostname === `${config.blobAccount}.blob.core.windows.net`)
                        return v + msal.sas;
                    else
                        return v;
                })
        };

        if (movie.runtime) {
            const [kbps, quality] = contentLengthToQuality(properties.contentLength!, movie.runtime);;
            movie.kbps = kbps;
            movie.quality = quality;
        }

        console.log(movie);

        return movie;
    }

    async backdrops(tt: string): Promise<string[]> {
        const container = blobServiceClient.getContainerClient('backdrops');
        const backdrops = [];

        for await (const blob of container.findBlobsByTags(`tt='${tt}'`)) {
            const blobClient = container.getBlobClient(blob.name);
            backdrops.push(blobClient.url);
        }

        return backdrops;
    }

    /**
     * Returns a list of episodic series that match the search criteria.
     * @param query - Search query
     */
    async search(query: string): Promise<Movie[]> {
        const movies = [];

        for await (const movie of this.list())
            movies.push(movie);

        const resultsByTitle = fuzzy.filter(query, movies, {
            extract: m => m.title
        });
        const resultsByTitleAndTags = fuzzy.filter(query, movies, {
            extract: m => `${m.title}|${m.tags.join('|')}`
        });

        const distinct = [
            ...resultsByTitle,
            ...resultsByTitleAndTags
        ].reduce((map, m) => {
            if (m.original.id in map === false)
                map[m.original.id] = m;
            else if (m.score > map[m.original.id].score)
                map[m.original.id] = m;

            return map;
        }, {} as { [id: string]: fuzzy.FilterResult<Movie> });

        const results = [];

        // If we've matches with a score of 100+ it's that one
        for (const threshold of [100, 50, 10]) {
            results.push(...Object.values(distinct).filter(r => r.score > threshold).map(r => r.original));

            if (results.length)
                return results;
        }

        return [];
    }

    /**
     * Returns a list of movies that match the search criteria.
     * @param func - function applied against elements
     * @return with elements transformed by callback
     */
    async* filter(func: (movie: Movie) => boolean): AsyncIterable<Movie> {
        for await (const movie of this.list()) {
            if (func(movie))
                yield movie;
        }
    }
}
(window as any).movieManager = new MovieManager();
