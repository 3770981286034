import { Movie } from '@types';
import MovieManager from 'managers/MovieManager';
import { useEffect, useRef, useState } from 'react';
import NetflixRowHeader from '../Netflix.Header';
import NetflixTile from '../NetflixTile';
import NetflixRowLoading from './NetflixRowLoading';
import { EMPTY_VIDEO, TILES_PER_ROW, TILE_WIDTH } from './config';

const movieManager = new MovieManager();

interface NetflixRowGenreProps extends React.HTMLAttributes<HTMLDivElement> {
    id: string;
    title: string;
    filter: (movie: Movie) => boolean;
    sorting: (left: Movie, right: Movie) => number;
    limit?: number;
    href?: string;
    onShowVideoDetails?: (movie: Movie) => void;
}

/** Component that displays a single scrollable row of Netflix-style video tiles. */
export default function NetflixRowGenre(props: NetflixRowGenreProps) {
    const [loading, setLoading] = useState(true);
    const [movies, setMovies] = useState<Movie[]>([]);
    const [pages, setPages] = useState(Array(5).fill(0).map((_, i) => i + 1));
    const [index, setIndex] = useState(0);
    const [touched, setTouched] = useState(false);
    const refSliderContent = useRef<HTMLDivElement>(null);
    const { id, filter, sorting, limit } = props;

    useEffect(() => {
        let cancelled = false;

        (async () => {
            const _movies = [];

            for await (const movie of movieManager.filter(filter))
                _movies.push(movie);

            if (cancelled)
                return;
            if (_movies.length) {
                const limited = _movies.sort(sorting || (_ => 0)).slice(0, limit || 24);
                setMovies(limited);
                setPages(Array(Math.ceil(limited.length / TILES_PER_ROW)).fill(0).map((_, i) => i + 1));
            }

            setLoading(false);
        })();

        return () => cancelled = true as any;
    }, [id, filter, sorting, limit]);

    // Callback for when the user advances or retreats the video scroller
    const handlePagination = (i: number) => {
        // Same pg
        if (i === index)
            return;

        const $content = refSliderContent.current;
        let _index = i;

        if (!$content)
            return;

        // Wrap around to first
        if (_index > pages.length - 1)
            _index = 0;

        // Wrap around to last
        else if (_index < 0)
            _index = pages.length - 1;

        const start = _index * TILES_PER_ROW;
        const end = start + TILES_PER_ROW;
        const current = movies.slice(start, end);
        const next = movies.slice(end, end + TILES_PER_ROW);
        $content.classList.add('animating');

        // 1st => Next
        if (!touched && i > index)
            $content.style['transform'] = `translate3d(-${current.length * TILE_WIDTH}%, 0px, 0px)`;

        // Next
        else if (i > index) {
            // Not a full scroll
            if (current.length < TILES_PER_ROW)
                $content.style['transform'] = `translate3d(-${100 + current.length * TILE_WIDTH}%, 0px, 0px)`;

            else
                $content.style['transform'] = `translate3d(-200%, 0px, 0px)`;
        }

        // Prev
        else {
            // Not a full scroll b/c this is "pg n => pg n-1" and "pg n" does not have a full row
            if (next.length && next.length < TILES_PER_ROW)
                $content.style['transform'] = `translate3d(-${100 - next.length * TILE_WIDTH}%, 0px, 0px)`;

            else
                $content.style['transform'] = `translate3d(0%, 0px, 0px)`;
        }

        // 750ms is how long the animation lasts (netflix.css:9332)
        setTimeout(() => {
            setIndex(_index);
            setTouched(true);
            $content.classList.remove('animating');
            $content.style['transform'] = `translate3d(-100%, 0px, 0px)`;
        }, 700);
    };

    const start = index * TILES_PER_ROW;
    const end = start + TILES_PER_ROW;
    // const tiles = Array(TILES_PER_ROW * 3 + 2).fill(EMPTY_VIDEO);
    const current = movies.slice(start, end);
    const previous: (Movie & { key?: React.Key })[] = [];
    const next: (Movie & { key?: React.Key })[] = [...movies.slice(end, end + TILES_PER_ROW)];

    // Just the first 6 tiles, 1 halfway, then the next 6 for another pg
    if (touched) {
        if (index === 0)
            previous.push(...movies.slice(movies.length - TILES_PER_ROW));

        else
            previous.push(...movies.slice(Math.max(start - TILES_PER_ROW, 0), start));
    }

    // Last pg - set next to 1st 6
    if (index === pages.at(-1)! - 1)
        next.push(...movies.slice(0, TILES_PER_ROW));

    // Not enough movies for a complete row - fill out with empty ones
    else if (next.length < TILES_PER_ROW)
        next.push(...Array(TILES_PER_ROW - next.length).fill(EMPTY_VIDEO));
    if (touched && previous.length < TILES_PER_ROW)
        previous.splice(0, 0, ...Array(TILES_PER_ROW - previous.length).fill(EMPTY_VIDEO));
    if (current.length < TILES_PER_ROW)
        previous.splice(0, 0, ...Array(TILES_PER_ROW - current.length).fill(EMPTY_VIDEO));

    // Make sure all the keys are unique
    for (const [i, tile] of Object.entries(previous)) {
        // Append `-prev` to all duplicate tiles
        if (current.find(t => t.id === tile.id) || next.find(t => t.id === tile.id))
            previous[i as any] = { ...tile, key: `${tile.id}-prev` };
    }
    for (const [i, tile] of Object.entries(next)) {
        // Do *not* append `-next` to all duplicate tiles because it causes the tiles to flicker if react has to destroy/create a tile
        if (current.find(t => t.id === tile.id))
            next[i as any] = { ...tile, key: `${tile.id}-next` };
    }

    if (current.length < TILES_PER_ROW) {
        previous.splice(0, previous.length);
        next.splice(0, next.length);
    }

    const tiles: (Movie & { key?: React.Key })[] = [...previous, ...current, ...next];
    const renderme = !(!loading && !tiles.length && props.title === 'Continue Watching');

    return (
        <>
            {
                renderme &&
                <>
                    {
                        loading &&
                        <NetflixRowLoading />
                    }

                    {
                        !loading &&
                        <div className="lolomoRow lolomoRow_title_card css-0" data-list-context="genre">
                            <NetflixRowHeader title={props.title} href={props.href} />
                            <div className="rowContainer rowContainer_title_card">
                                <div className="ptrack-container">
                                    <div className="rowContent slider-hover-trigger-layer">
                                        <div className="slider">
                                            {
                                                touched &&
                                                <span onClick={() => handlePagination(index - 1)}
                                                    className="handle handlePrev active"
                                                    tabIndex={0}
                                                    role="button"
                                                    title="See previous titles"
                                                    aria-label="See previous titles"
                                                >
                                                    <b className="indicator-icon icon-leftCaret"></b>
                                                </span>
                                            }

                                            <ul className="pagination-indicator">
                                                {pages.map(p => <li key={p} className={(p - 1 === index ? 'active' : '')} />)}
                                            </ul>
                                            <div className="sliderMask showPeek">
                                                <div ref={refSliderContent} className="sliderContent row-with-x-columns">
                                                    {
                                                        tiles.map((movie, i) => {
                                                            return (
                                                                <NetflixTile key={movie.key ?? movie.id ?? i}
                                                                    index={i}
                                                                    model={movie}
                                                                    onShowVideoDetails={props.onShowVideoDetails}
                                                                    data-label={movie.title}
                                                                />
                                                            );
                                                        })
                                                    }
                                                </div>
                                            </div>

                                            {
                                                pages.length > 1 &&
                                                <span onClick={() => handlePagination(index + 1)}
                                                    className="handle handleNext active"
                                                    tabIndex={0}
                                                    role="button"
                                                    title="See more titles"
                                                    aria-label="See more titles"
                                                >
                                                    <b className="indicator-icon icon-rightCaret" />
                                                </span>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </>
            }
        </>
    );
}
