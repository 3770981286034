import { Episodic } from '@types';
import { ReactComponent as ImdbSvg } from 'assets/imdb.svg';
import Icon from 'components/Icon';
import { Button, Modal } from 'react-bootstrap';
import EpisodicLister from './EpisodicLister';

interface EpisodicViewerProps {
    model?: Episodic;
    show: boolean;
    onHide?: () => void;
}

/** Component for displaying all the episodes for an episodic series grouped by season, in a modal. */
export default function EpisodicViewer(props: EpisodicViewerProps) {
    const { title, seasons, imdb } = props.model || {};
    const handleHide = () => {
        props.onHide?.();
    };

    return (
        <Modal show={props.show} onHide={handleHide} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <EpisodicLister model={seasons ?? []} />
                {
                    !!imdb &&
                    <a href={`https://imdb.com/title/${imdb}/`} className="position-absolute bottom-0 mb-3" target="_blank" rel="noreferrer">
                        <span className="d-flex align-items-center mt-1">
                            <ImdbSvg />
                            <Icon variant="open-outline" className="ms-1 text-light" />
                        </span>
                    </a>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}
