import NetflixBrowser from './Netflix';
import NetflixTile from './NetflixTile';
import NetflixRowGenre from './rows/NetflixRowGenre';
import NetflixRowLoading from './rows/NetflixRowLoading';
import NetflixRowPopular from './rows/NetflixRowPopular';
import NetflixRowQueried from './rows/NetflixRowQueried';
import NetflixRowStatic from './rows/NetflixRowStatic';

export {
    NetflixBrowser, NetflixRowGenre,
    NetflixRowLoading, NetflixRowPopular, NetflixRowQueried, NetflixTile
};

const Netflix = {
    Browser: NetflixBrowser,
    Popular: NetflixRowPopular,
    Genre: NetflixRowGenre,
    Loading: NetflixRowLoading,
    Tile: NetflixTile,
    Queried: NetflixRowQueried,
    Static: NetflixRowStatic
};

export default Netflix;
