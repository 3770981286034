interface NetflixRowHeaderProps {
    title: string;
    href?: string;
}

/** Component used in Netflix Rows to display the title. */
export default function NetflixRowHeader(props: NetflixRowHeaderProps) {
    return (
        <h2 className="rowHeader css-0">
            {
                !!props.href &&
                <a
                    className="rowTitle css-0"
                    href={props.href}
                >
                    <div className="row-header-title">{props.title}</div>
                    <div className="aro-row-header more-visible">
                        <div className="see-all-link">Explore All</div>
                        <div className="aro-row-chevron icon-akiraCaretRight" />
                    </div>
                </a>
            }
            {
                !props.href &&
                <div className="rowTitle css-0">
                    <div className="row-header-title">{props.title}</div>
                    <div className="aro-row-header more-visible">
                        <div className="see-all-link">Explore All</div>
                        <div className="aro-row-chevron icon-akiraCaretRight" />
                    </div>
                </div>
            }
        </h2>
    );
}
