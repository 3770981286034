import React from "react";

interface IconProps extends React.HTMLAttributes<HTMLElement> {
    variant: string;
}

/** Component that renders an icon. */
export default function Icon(props: IconProps) {
    const { variant, className, ...rest } = props;
    const classNames = ['icon'];

    if (className)
        classNames.push(className);
    
    return React.createElement('ion-icon', {
        name: props.variant,
        class: classNames.join(' '),
        ...rest
    });
}
