/* eslint-disable no-sequences */
import { BlobServiceClient, BlockBlobUploadOptions } from '@azure/storage-blob';
import { Episode, Episodic } from '@types';
import { v4 as uuidv4 } from 'uuid';
import config, { msal } from '../config';

const blobServiceClient = new BlobServiceClient(`https://${config.blobAccount}.blob.core.windows.net${msal.sas}`);

export default class EpisodicManager {
    static #cache: { [id: string]: Episodic } = {};

    /** Returns a grouped list of shows by season. */
    async* list(): AsyncIterable<Episodic> {
        if (Object.keys(EpisodicManager.#cache).length) {
            for (const episodic of Object.values(EpisodicManager.#cache))
                yield Promise.resolve(episodic);
        }
        else {
            const container = blobServiceClient.getContainerClient('tv-shows');
            const blobs = container.listBlobsFlat({ includeMetadata: true, includeTags: true });
            const episodics: { [title: string]: Episodic } = {};

            for await (const blob of blobs) {
                if (!blob.tags) {
                    console.warn(`Missing tag data for ${blob.name}`, blob);
                    continue;
                }
                const metadata = blob.metadata!;

                const episode: Episode = {
                    id: blob.name,
                    title: unescape(blob.tags.title.replace(/=/g, '%')),
                    series: unescape(blob.tags.series.replace(/=/g, '%')),
                    season: Number(metadata.season),
                    episode: Number(metadata.episode),
                    tags: Object.entries(blob.tags).filter(([_, v]) => !v).map(([k]) => k),
                    contentType: blob.properties.contentType,
                    createdOn: blob.properties.createdOn!,
                    imdb: metadata.imdb,
                    poster: metadata.poster,
                    airdate: metadata.airdate,
                    storyline: metadata.storyline ? unescape(metadata.storyline.replace(/=/g, '%')) : undefined,
                    mpaa: metadata.mpaa,
                    runtime: metadata.runtime
                };
                episodics[episode.series] = episodics[episode.series] || { title: episode.series, seasons: [], backdrops: [] };
                const show = episodics[episode.series];
                let season = show.seasons.find(s => s.id === episode.season);

                if (!season)
                    season = show.seasons[show.seasons.length] = { id: episode.season, episodes: [] };

                season.episodes.push(episode);
                show.poster = show.poster || metadata['series_poster'];
                show.imdb = show.imdb || metadata['series_imdb'];

                if (!show.backdrops.length) {
                    show.backdrops = Object.entries(metadata)
                        .filter(([k]) => k.startsWith('backdrop_')).map(([_, v]) => {
                            if (new URL(v).hostname === `${config.blobAccount}.blob.core.windows.net`)
                                return v + msal.sas;
                            else
                                return v;
                        });
                }

                if (metadata['series_poster'] && `${episode.season}:${episode.episode}` !== '1:1')
                    console.warn(`Found non-pilot episode with series meta.`, episode);

                const episodic: Episodic = {
                    title: show.title,
                    imdb: show.imdb,
                    poster: show.poster,
                    backdrops: show.backdrops,
                    mpaa: episode.mpaa,
                    seasons: show.seasons
                        .sort((l, r) => l.id === r.id ? 0 : l.id > r.id ? 1 : -1)
                        .map(s => ({
                            id: s.id,
                            episodes: s.episodes.sort((l, r) => l.episode === r.episode ? 0 : l.episode > r.episode ? 1 : -1)
                        }))
                };

                EpisodicManager.#cache[episodic.title] = episodic;
                yield episodic;
            }

            (window as any).episodics = EpisodicManager.#cache;

            // TODO: One time fix
            for (const key in EpisodicManager.#cache) {
                for (const season of EpisodicManager.#cache[key].seasons) {
                    for (const episode of season.episodes) {
                        if (`${episode.id}:currentTime` in window.localStorage) {
                            window.localStorage[`${episode.id}:meta`] = JSON.stringify({ currentTime: Number(window.localStorage[`${episode.id}:currentTime`]), lastWatched: 0 });
                            window.localStorage.removeItem(`${episode.id}:currentTime`);
                        }
                    }
                }
            }
        }
    }

    /**
     * Uploads the show to azure blob.
     * @param {Function<Number>} onProgress - callback when there is upload progress
     */
    async upload({ series, season, episode, title, tags, file, poster }: Episode & { file: File }, onProgress: (progress: number) => void): Promise<HttpResponseError & { id?: string }> {
        const content = await file.arrayBuffer();
        const id = uuidv4();
        const options: BlockBlobUploadOptions = {
            blobHTTPHeaders: {
                blobContentType: file.type
            },
            metadata: {
                season: season.toString(),
                episode: episode.toString()
            },
            tags: {
                series,
                title: escape(title).replace(/(%20)/g, ' ').replace('%', '='),
                ...tags.reduce((map, t) => (map[t] = '', map), {} as { [tag: string]: string })
            },
            onProgress: ({ loadedBytes }) => onProgress?.(Number((loadedBytes / content.byteLength).toPrecision(2)) * 1)
        };

        if (poster)
            options.metadata!.poster = poster;

        const container = blobServiceClient.getContainerClient('tv-shows');
        const blockBlobClient = container.getBlockBlobClient(id);

        try
        {
            const response = await blockBlobClient.upload(content, content.byteLength, options);

            return {
                id,
                status: response._response.status,
                ok: response._response.status >= 200 && response._response.status <= 299
            };
        }
        catch (ex: any) {
            return {
                status: 500,
                ok: false,
                errors: { '': ex.message }
            };
        }
    }

    /**
     * Updates the episodes' values.
     * @param {string} id - Unique identifier
     * @param {Episode} values - Values to update
     * @returns - updated episode.
     */
    async update(id: string, values: Episode): Promise<Episode> {
        const container = blobServiceClient.getContainerClient('tv-shows');
        const blobClient = container.getBlobClient(id);
        const tags = await blobClient.getTags().then(({ tags }) => tags);
        const metadata = await blobClient.getProperties().then(r => r.metadata!);
        const xhrs = [];

        if (values.airdate)
            metadata['airdate'] = values.airdate;
        if (values.imdb)
            metadata['imdb'] = values.imdb;
        if (values.mpaa)
            metadata['mpaa'] = values.mpaa;
        if (values.runtime)
            metadata['runtime'] = values.runtime;
        if (values.storyline)
            metadata['storyline'] = escape(values.storyline).replace(/(%20)/g, ' ').replace('%', '=');
        if (values.poster)
            metadata['poster'] = values.poster;

        if (values.title)
            tags['title'] = escape(values.title).replace(/(%20)/g, ' ').replace('%', '=');
        if (values.tags && Array.isArray(values.tags) && values.tags.length)
            tags['tags'] = values.tags?.join(' ');

        if (Object.keys(tags).length)
            xhrs.push(blobClient.setTags(tags));
        if (Object.keys(metadata).length)
            xhrs.push(blobClient.setMetadata(metadata));

        await Promise.all(xhrs);

        return await this.get(id);
    }

    /**
     * Returns the episode content
     * @param id - Id of the episode
     */
    async get(id: string): Promise<Episode> {
        const container = blobServiceClient.getContainerClient('tv-shows');
        const blobClient = container.getBlobClient(id);
        const { tags } = await blobClient.getTags();
        const { series, title } = tags;
        const properties = await blobClient.getProperties();
        const { season, episode, imdb, poster, airdate, mpaa, runtime, storyline } = properties.metadata!;

        const ep: Episode = {
            id: blobClient.name,
            series,
            season: Number(season),
            episode: Number(episode),
            title: unescape(title.replace(/=/g, '%')),
            imdb, poster, airdate, mpaa, runtime,
            storyline: storyline ? unescape(storyline.replace(/=/g, '%')) : undefined,
            url: blobClient.url,
            contentType: properties.contentType,
            createdOn: properties.createdOn!,
            tags: tags.tags?.split(' ').sort() ?? []
        };

        console.log(ep);

        return ep;
    }

    /**
     * Retrieves all the seasons & episodes in an episodic series.
     * @param {string} name - series name
     * @returns {Promise<Episodic>}
     */
    async getSeries(name: string): Promise<Episodic> {
        if (name in EpisodicManager.#cache)
            return EpisodicManager.#cache[name];

        const series: Episodic = {
            title: name,
            imdb: 'tt',
            seasons: [],
            poster: '',
            backdrops: []
        };
        const xhrs = [];

        for await (const blob of blobServiceClient.findBlobsByTags(`series='${name}'`))
            xhrs.push(this.get(blob.name));

        const episodes = await Promise.all(xhrs);

        for (const episode of episodes.sort((l, r) => l.season === r.season ? l.episode > r.episode ? 1 : -1 : l.season > r.season ? 1 : -1)) {
            series.seasons[episode.season] = series.seasons[episode.season] || { id: episode.season, episodes: [] };
            series.seasons[episode.season].episodes.push(episode);

            if (!series.poster)
                series.poster = episode.poster;
        }

        series.seasons = [...Object.values(series.seasons)];

        return series;
    }

    /**
     * Returns a list of episodic series that match the search criteria.
     * @param query - Search query
     */
    async* search(query: string): AsyncIterable<Episodic> {
        const re = new RegExp(query.trim().split(/\s+/g).filter(s => !/on|the|in|and|of|to/i.test(s)).join('|'), 'gi');

        if (`${re}` === '/(?:)/gi')
            return;

        for await (const series of this.list()) {
            if (re.test(series.title))
                yield series;
        }
    }

    /**
     * Returns a list of episodics that match the search criteria.
     * @param func - function applied against elements
     * @return with elements transformed by callback
     */
    async* filter(func: (series: Episodic) => boolean): AsyncIterable<Episodic> {
        for await (const episodic of this.list()) {
            if (func(episodic))
                yield episodic;
        }
    }
}
(window as any).episodicManager = new EpisodicManager();
